import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Col,
  Row,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import { useAuth } from "model/context/auth.context"; // Import useAuth
import { useFirebase } from "model/context/firebase.context";
import { COLLECTIONS } from "model/constants";

export function ProjectTransactionsModal({
  internal,
  openModal,
  setModalOpen,
  setError,
  projectId,
}) {
  const [loading, setLoading] = useState(false);
  const { session } = useAuth();
  const { db } = useFirebase();

  const [transactionValue, setTransactionValue] = useState(0);
  const [transactionDate, setTransactionDate] = useState("");
  const [receiveOrPay, setReceiveOrPay] = useState(false);
  const [paid, setPaid] = useState(false);
  const [transactionMemo, setTransactionMemo] = useState("");

  const resetState = () => {
    setTransactionValue(0);
    setTransactionDate("");
    setReceiveOrPay(false);
    setPaid(false);
    setTransactionMemo("");
  }

  const handleDropdownReceiveorPaySelect = (e, value) => {
    e.preventDefault();
    setReceiveOrPay(value);
  };
  const onSave = async () => {
    try {
      setLoading(true);
      if (internal.current) {
        const transaction = {
          userName: session.user.displayName || session.user.email,
          createdAt: new Date().toISOString(),
          transactionValue,
          transactionDate,
          receiveOrPay,
          paid,
          transactionMemo,
        };
        try {
          await db.insert(
            `${COLLECTIONS.projects}/${projectId}/transactions`,
            transaction
          );
          resetState();
        } catch (e) {
          console.log("first try error", e);
        }
        setModalOpen(false);
      }
    } catch (e) {
      setError(e.message || "Cannot save transactions due to an error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        isOpen={openModal}
        toggle={() => {
          setModalOpen(false);
        }}
      >
        <ModalHeader>
          <h3>Transactions</h3>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={6}>
              <FormGroup>
                <label className="form-control-label font-weight-light">
                  Transaction Value
                </label>
                <Input
                  required
                  name="Transaction Value"
                  placeholder="Transaction"
                  type="number"
                  value={transactionValue}
                  onChange={(e) => setTransactionValue(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <label className="form-control-label font-weight-light">
                  Transaction Date
                </label>
                <Input
                  required
                  name="TransactionDate"
                  placeholder="Transaction Date"
                  type="date"
                  value={transactionDate}
                  onChange={(e) => setTransactionDate(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <label className="form-control-label font-weight-light">
                  Receive or Pay
                </label>
                <br></br>
                <Input
                  hidden
                  name="ReceiveorPay"
                  className="form-control text-dark"
                  placeholder="Receive or Pay"
                  value={receiveOrPay ? "Receive" : "Pay"}
                  readOnly
                />
                <UncontrolledDropdown>
                  <DropdownToggle caret color="primary">
                    {receiveOrPay ? "Receive" : "Pay"}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      href="#"
                      onClick={(e) => handleDropdownReceiveorPaySelect(e, true)}
                    >
                      Receive
                    </DropdownItem>
                    <DropdownItem
                      href="#"
                      onClick={(e) =>
                        handleDropdownReceiveorPaySelect(e, false)
                      }
                    >
                      Pay
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <div className="d-flex align-items-center">
                  <small className="form-control-label font-weight-light mr-3">
                    Paid?
                  </small>
                  <div>
                    <label className="custom-toggle">
                      <input
                        type="checkbox"
                        checked={paid}
                        onChange={(e) => setPaid(e.target.checked)}
                      />
                      <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off="No"
                        data-label-on="Yes"
                      />
                    </label>
                  </div>
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <FormGroup>
                <label className="form-control-label font-weight-light">
                  Transaction Memo
                </label>
                <Input
                  required
                  name="Transaction Memo"
                  placeholder="Transaction Memo"
                  type="textarea"
                  value={transactionMemo}
                  onChange={(e) => setTransactionMemo(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="light"
            type="button"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            className="ml-4"
            color="danger"
            type="button"
            onClick={onSave}
            disabled={loading}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
