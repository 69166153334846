import React from 'react';

const renderErrorItem = (key, error, parentKey = '') => {
  const fullKey = parentKey ? `${parentKey}.${key}` : key;

  if (typeof error === 'string') {
    return <li key={fullKey}>{error}</li>;
  } else if (Array.isArray(error)) {
    return error.map((item, index) => (
      <React.Fragment key={`${fullKey}-${index}`}>
        {renderErrorItem(`${fullKey}[${index}]`, item)}
      </React.Fragment>
    ));
  } else if (typeof error === 'object' && error !== null) {
    return Object.entries(error).map(([subKey, subError]) => (
      renderErrorItem(subKey, subError, fullKey)
    ));
  } else {
    return <li key={fullKey}>{String(error)}</li>;
  }
};

const ErrorDisplay = ({ errors }) => {
  if (!errors || Object.keys(errors).length === 0) {
    return null;
  }

  return (
    <div className="alert alert-danger" role="alert">
      <ul>
        {Object.entries(errors).map(([key, error]) => renderErrorItem(key, error))}
      </ul>
    </div>
  );
};

export default ErrorDisplay;