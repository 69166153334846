/* eslint-disable react-hooks/exhaustive-deps */
import { LoaderSpinner } from 'components/Loader/LoaderSpinner';
import { COLLECTIONS } from 'model/constants';
import { useFirebase } from 'model/context/firebase.context';
import { nanoid } from 'nanoid';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Button, Col, FormGroup, Input, Modal, Row, Spinner } from 'reactstrap';
import slugify from 'slugify';

function SupplierNameInput({
    suppliers,
    supplier,
    index,
}) {
    const [msg, setMessage] = useState();
    const [supplierId, setSupplierId] = useState(supplier?.id || '');
    return (
        <FormGroup>
            <label className="form-control-label font-weight-light">Name</label>
            <Input
                name={`supplierId-${index}`}
                className={`form-control text-dark`}
                type="text"
                readOnly
                hidden
                value={supplierId}
            />
            <Input
                name={`supplierName-${index}`}
                className={`form-control text-dark`}
                placeholder="Supplier Name"
                type="text"
                defaultValue={supplier.name}
                list="suppliers"
                onChange={(e) => {
                    let _msg = '';
                    const { value } = e.target;
                    if (value) {
                        const opts = { lower: true };
                        const exists = suppliers.find((item) => slugify(item.name, opts) === slugify(value, opts));
                        if (!exists) {
                            _msg = 'Supplier not saved';
                            if (supplierId) setSupplierId('');
                        }
                        else setSupplierId(exists.id);
                    }
                    if (_msg !== msg) setMessage(_msg);
                }}
            />
            {msg && <label className="form-control-label font-weight-light text-sm">{msg}</label>}
        </FormGroup>
    )
}

export function MaterialsModal({
    trades,
    suppliers,
    materials,
    open,
    onClose,
    selectedItem,
    onDidCreate,
}) {
    const defaultSuppliers = () => [{ renderKey: nanoid(), id: '', name: '', date: new Date().toISOString().replace(/T.*/, ''), cost: 0 }];
    const confirmDeleteTimeout = useRef();
    const [error, setError] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [tab, setTab] = useState(0);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [enableConfirmDelete, setEnableConfirmDelete] = useState(false);
    const [selectedSuppliers, setSelectedSuppliers] = useState(selectedItem?.suppliers || defaultSuppliers());
    const { db } = useFirebase();
    const config = {
        formId: '#new-material-form',
        collection: COLLECTIONS.materials,
        typeLabel: 'Supply / Material',
    };
    const validateElement = (element) => {
        const  { required, value, type, name } = element;
        if (name === 'note' && !!value?.trim()) {
            return 'add note or delete content';
        } else if (required && !value) {
            return 'required';
        } else if (type === 'email' && !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(value)) {
            if (!value && !required) return null;
            return 'invalid email';
        }
    }
    const onValidate = () => {
        const errors = {};
        const form = document.querySelector(config.formId);
        for (let element of form.elements) {
            const  { type, name } = element;
            if (type === 'button') continue;
            const error = validateElement(element);
            if (error) errors[name] = error;
        }
        return errors;
    }
    const onSubmit = async () => {
        const errors = onValidate();
        const updatedSuppliers = [...selectedSuppliers.map(({ renderKey, ...supplier}) => supplier)];
        const values = {};
        const form = document.querySelector(config.formId);
        for (let element of form.elements) {
            const  { value, checked, type, name } = element;
            if (type === 'button' || name === 'note') continue;
            if (name.match(/-\d$/) && name.includes('supplier')) {
                const index = parseInt(name.match(/\d$/)[0])
                const key = name.includes('Name') ? 'name' : name.includes('Cost') ? 'cost' : name.includes('Date') ? 'date' : 'id';
                updatedSuppliers[index][key] = typeof value === 'string' ? value.trim() : value;
                continue;
            }
            values[name] = type === 'checkbox' ? checked : (typeof value === 'string' ? value.trim() : value);
            if (type === 'number') values[name] = +values[name];
        }

        values.suppliers = [...updatedSuppliers.filter(({ renderKey, id, ...supplier }) => Object.values(supplier).every(value => !!value))];
        if (!values.suppliers.length) {
            errors['suppliers'] = 'at least one supplier and cost must be included'
        }
        if (Object.keys(errors).length) {
            setErrors(errors);
            setError('There are errors in the form.');
        } else {
            setLoading(true);
            try {
                const trade = form.elements['trade']?.value;
                if (trade?.trim() && !trades.find(item => slugify(item, { lower: true }) === slugify(trade, { lower: true }))) {
                    await db.push(`${COLLECTIONS.trades}/${slugify(trade, { lower: true })}`, trade.trim());
                }
                for(let i = 0; i < values.suppliers.length; i++) {
                    const supplier = values.suppliers[i];
                    if (!supplier.id) {
                        const insertedKey = await db.insert(COLLECTIONS.suppliers, { name: supplier.name });
                        values.suppliers[i].id = insertedKey
                    }
                }
                if (selectedItem) {
                    await db.update(config.collection, selectedItem.id, values);
                    onClose();
                } else {
                    const opts = { lower: true };
                    const exists = materials.find((material) => slugify(material.name, opts) === slugify(values.name, opts));
                    if (exists) throw new Error('Item name already exists.');
                    const insertedKey = await db.insert(config.collection, values);
                    if (insertedKey) {
                        onClose();
                        if (onDidCreate) onDidCreate({ id: insertedKey, ...values });
                    } else {
                        throw new Error('Record not generated')
                    }
                }
            } catch (e) {
                setError(`${config.typeLabel} was not saved due to an error: ${e?.messasge || e}`)
            } finally {
                setLoading(false);
            }
        }
    }
    const onDelete = async () => {
        setLoading(true);
        try {
            if (selectedItem) await db.delete(config.collection, selectedItem.id);
            onClose();
        } catch (e) {
            setError(`${config.typeLabel} was not deleted due to an error: ${e?.messasge || e}`)
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        if (!open) {
            setErrors({});
            setError('');
            setTab(0);
            setConfirmDelete(false);
            setEnableConfirmDelete(false);
            setSelectedSuppliers(defaultSuppliers());
            clearTimeout(confirmDeleteTimeout.current);
        } else {
            setSelectedSuppliers(selectedItem?.suppliers || defaultSuppliers());
        }
    }, [open]);

    useEffect(() => {
        setError('');
    }, [tab]);

    const renderSuppliers = selectedSuppliers.map((supplier, index) => (
        <Fragment key={supplier.renderKey || supplier.id}>
            <Col md="4">
                <SupplierNameInput
                    suppliers={suppliers}
                    supplier={supplier}
                    index={index}
                />
            </Col>
            <Col md="4">
                <FormGroup>
                    <label className="form-control-label font-weight-light">Cost</label>
                    <Input
                        min="0"
                        name={`supplierCost-${index}`}
                        className={`form-control text-dark`}
                        placeholder="Cost"
                        type="number"
                        defaultValue={supplier.cost || ''}
                    />
                </FormGroup>
            </Col>
            <Col md="4">
                <FormGroup>
                    <label className="form-control-label font-weight-light">Cost Revision Date</label>
                    <Input
                        name={`supplierDate-${index}`}
                        className={`form-control text-dark`}
                        placeholder="Last Cost Update Date"
                        type="date"
                        defaultValue={supplier.date}
                    />
                </FormGroup>
            </Col>
            <Col md="12">
                <hr style={{ margin: '0 0 1em 0', padding: 0, }} />
            </Col>
        </Fragment>
    ));

    return (
        <Modal
            isOpen={open}
            className="modal-dialog-centered modal-white modal-xl"
        >
            <div className="p-3">
                <div className="modal-body" style={{
                    minHeight: 450,
                }}>
                    <form id={config.formId.replace('#', '')} autoComplete="off" className="h-100" onChange={(e) => {
                        const error = validateElement(e.target);
                        const name = e.target.name;
                        setErrors({ ...errors, [name]: error });
                    }}>
                        <div className="d-md-flex flex-row justify-content-between align-items-start mb-5">
                            <h3 className="text-muted font-weight-bold">
                                {tab === 0 
                                    ? 'Basic Information'
                                    : 'Suppliers and costs'
                                }
                            </h3>
                            {!!selectedItem && (
                                <div className="d-flex flex-flow justify-content-between">
                                    {confirmDelete && (
                                    <Button
                                        size="sm"
                                        color="light"
                                        type="button"
                                        onClick={() => {
                                            setConfirmDelete(false);
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    )}
                                    <Button
                                        size="sm"
                                        className="ml-4"
                                        color="danger"
                                        type="button"
                                        disabled={confirmDelete && !enableConfirmDelete}
                                        onClick={() => {
                                            if (confirmDelete) {
                                                return onDelete();
                                            }
                                            clearTimeout(confirmDeleteTimeout.current);
                                            setEnableConfirmDelete(false);
                                            confirmDeleteTimeout.current = setTimeout(() => {
                                                setEnableConfirmDelete(true);
                                            }, 2000);
                                            setConfirmDelete(!confirmDelete)
                                        }}
                                    >
                                        {confirmDelete ? 'Confirm Delete' : `Delete ${config.typeLabel}`}{confirmDelete && !enableConfirmDelete && <Spinner size="sm" className="ml-2" />}
                                    </Button>
                                </div>)}
                        </div>

                        <Row className={tab !== 0 ? "d-none" : ''}>
                            <Col md="6">
                                <FormGroup>
                                    <label className="form-control-label font-weight-light">Item Name<sup className={!!errors?.name ? 'text-danger' : ''}>* {errors.name}</sup></label>
                                    <Input
                                        required
                                        name="name"
                                        className={`form-control text-dark ${errors?.name ? 'is-invalid' : ''}`}
                                        placeholder="Item Name"
                                        type="text"
                                        defaultValue={selectedItem?.name}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label className="form-control-label font-weight-light">Trade<sup className={!!errors?.trade ? 'text-danger' : ''}>* {errors.trade}</sup></label>
                                    <Input
                                        required
                                        name="trade"
                                        className={`form-control text-dark ${errors?.trade ? 'is-invalid' : ''}`}
                                        placeholder="Trade"
                                        type="text"
                                        defaultValue={selectedItem?.trade}
                                        list="trades"
                                    />
                                    <datalist id="trades">
                                        {trades.sort().map((item) => (
                                            <option key={item} value={item} />
                                        ))}
                                    </datalist>
                                </FormGroup>
                                <Row>
                                    <Col md="6">
                                        <FormGroup>
                                            <label className="form-control-label font-weight-light">Price<sup className={!!errors?.price ? 'text-danger' : ''}>* {errors.price}</sup></label>
                                            <Input
                                                required
                                                min="0"
                                                name="price"
                                                className={`form-control text-dark ${errors?.price ? 'is-invalid' : ''}`}
                                                placeholder="Price"
                                                type="number"
                                                defaultValue={selectedItem?.price || ''}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <label className="form-control-label font-weight-light">Tax<sup className={!!errors?.tax ? 'text-danger' : ''}>* {errors.tax}</sup></label>
                                            <Input
                                                required
                                                min="0"
                                                name="tax"
                                                className={`form-control text-dark ${errors?.tax ? 'is-invalid' : ''}`}
                                                placeholder="Tax"
                                                type="number"
                                                defaultValue={selectedItem?.tax || ''}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>

                            <Col md="6">
                                <FormGroup className="h-100">
                                    <label className="form-control-label font-weight-light">Description</label>
                                    <Input
                                        style={{ resize: 'none' }}
                                        name="description"
                                        className={`form-control h-100 text-dark ${errors?.description ? 'is-invalid' : ''}`}
                                        placeholder="Description"
                                        type="textarea"
                                        defaultValue={selectedItem?.description}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row className={tab !== 1 ? "d-none" : ''}>
                            {renderSuppliers}
                            {errors?.suppliers && <label><sup className="text-danger">{errors.suppliers}</sup></label>}
                            <datalist id="suppliers">
                                {suppliers.sort().map((item) => (
                                    <option key={item.id} data-id={item.id} value={item.name} />
                                ))}
                            </datalist>
                            <Col md="12">
                                <Button
                                    size="sm" 
                                    color="link" 
                                    type="button"
                                    className="icon-sm m-auto d-flex"
                                    onClick={() => {
                                        setSelectedSuppliers([...selectedSuppliers, ...defaultSuppliers()])
                                    }}
                                ><i className="ni ni-fat-add" /></Button>
                            </Col>
                            
                        </Row>

                    </form>
                    {!!error && (
                        <div className="alert alert-danger" role="alert">
                            {error}
                        </div>
                    )}
                </div>
                <div className="modal-footer">
                    <Button
                        color="link"
                        type="button"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="ml-auto"
                        color="dark"
                        type="button"
                        onClick={() => setTab(tab - 1 <= 0 ? 0 : tab - 1)}
                    >
                        <i className="ni ni-bold-left" />
                    </Button>
                    {tab < 1
                    ? (     
                        <Button
                            className="ml-1"
                            color="dark"
                            type="button"
                            onClick={() => setTab(tab + 1)}
                        >
                            <i className="ni ni-bold-right" />
                        </Button>
                    )
                    : (
                        <Button
                            className="ml-1"
                            color={!!error ? 'danger' : 'info'}
                            type="button"
                            onClick={onSubmit}
                        >
                            <i className="ni ni-check-bold" />
                        </Button>
                    )}
                </div>
            </div>
            {loading && <LoaderSpinner />}
        </Modal>
    )
}