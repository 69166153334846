/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import {
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter,
} from "reactstrap";
import Dropzone from "dropzone";
import { useFirebase } from 'model/context/firebase.context';
import { COLLECTIONS } from 'model/constants';

function UploadImageForm ({
    currentSingleFile
}) {
    useEffect(() => {
        new Dropzone(document.getElementById("dropzone-single"), {
            url: "/",
            thumbnailWidth: null,
            thumbnailHeight: null,
            previewsContainer:
                document.getElementsByClassName("dz-preview-single")[0],
            previewTemplate:
                document.getElementsByClassName("dz-preview-single")[0].innerHTML,
            maxFiles: 1,
            acceptedFiles: "image/*",
            init: function () {
                this.on("addedfile", function (file) {
                    if (currentSingleFile.current) {
                        this.removeFile(currentSingleFile.current);
                    }
                    currentSingleFile.current = file;
                });
            },
        });
        document.getElementsByClassName("dz-preview-single")[0].innerHTML = "";
    }, []);
    return (
        <div
            className="dropzone dropzone-single"
            id="dropzone-single"
        >
            <div className="fallback">
                <div className="custom-file">
                    <input
                        className="custom-file-input"
                        id="project-uploads"
                        type="file"
                    />
                    <label
                        className="custom-file-label"
                        htmlFor="project-uploads"
                    >
                        Choose file
                    </label>
                </div>
            </div>
            <div className="dz-preview dz-preview-single">
            <div className="dz-preview-cover">
                <img
                alt="..."
                className="dz-preview-img"
                data-dz-thumbnail=""
                />
            </div>
            </div>
        </div>
    );
}

export function ProjectUploadImageModal({
    internal,
    openModal,
    setModalOpen,
    setError,
    onUpdate,
}) {
    const [loading, setLoading] = useState(false);
    const { storage } = useFirebase();
    const currentSingleFile = useRef(undefined);
    const onUploadFile = async () => {
        try {
            setLoading(true);
            if (currentSingleFile.current) {
                const { current: file } = currentSingleFile;
                const result = await storage.upload(file, `${COLLECTIONS.projects}/${internal.current}`);
                if (result) {
                    onUpdate(result.ref);
                }
                setModalOpen(false);
            }
        } catch (e) {
            setError(e.message || 'Cannot upload the file due to an error');
        } finally {
            setLoading(false);
        }
    }
    return (
        <>
            <Modal isOpen={openModal} toggle={() => { setModalOpen(false); }}>
                <ModalHeader><h3>Upload image</h3></ModalHeader>
                <ModalBody>
                    {openModal && <UploadImageForm currentSingleFile={currentSingleFile} />}
                </ModalBody>
                <ModalFooter>
                    <Button
                    color="light"
                    type="button"
                    onClick={() => {
                        setModalOpen(false);
                    }}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="ml-4"
                        color="danger"
                        type="button"
                        onClick={onUploadFile}
                        disabled={loading}
                    >
                        Upload
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}