import React, { useState } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import { ProjectCommentModal } from './ProjectCommentModal';
import moment from 'moment';

export function ProjectCommentsTimeline({
  internal,
  comments,
  setError,
}) {
  const [openModal, setModalOpen] = useState(false);

  return (
    <>
      <Card className="h-100 no-print">
        <CardHeader>
          <div className="d-flex">
            <h3>Comments Timeline</h3>
            <Button
              size="sm"
              color="link"
              type="button"
              className="ml-auto text-nowrap"
              onClick={() => { setModalOpen(true); }}
            >
              Add Comment
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          {comments.length > 0 ? (
            <div
              className="timeline timeline-one-side"
              data-timeline-axis-style="dashed"
              data-timeline-content="axis"
            >
              {comments.map(comment => (
                <div key={comment.id} className="timeline-block">
                  <span className="timeline-step badge-primary">
                    <i className="ni ni-chat-round" />
                  </span>
                  <div className="timeline-content">
                    <small className="text-muted font-weight-bold">
                      {comment.userName || 'Unknown User'} - {moment(comment.createdAt).format('MMM DD, YYYY')}
                    </small>
                    <h5 className="mt-3 mb-0">Comment</h5>
                    <p className="text-sm mt-1 mb-0" dangerouslySetInnerHTML={{ __html: comment.text?.replace(/\r|\n/, '<br />') }} />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-center">There're no comments.</p>
          )}
        </CardBody>
      </Card>
      <ProjectCommentModal
        internal={internal}
        openModal={openModal}
        setModalOpen={setModalOpen}
        setError={setError}
      />
    </>
  );
}

export default ProjectCommentsTimeline;
