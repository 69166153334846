import React, { useEffect, useState } from "react";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import { Field, ErrorMessage, FieldArray, useFormikContext } from "formik";

export const GenericFormPartHorizontal = (
  {
    options,
    isArray,
    items,
    errors,
    defaultValues,
    values,
    type
  }
) => {
  const [multipleOf, setMultipleOf] = useState(1);
  const formik = useFormikContext();
  //Find out if it's multiple of 2 or 3
  useEffect(() => {
    if (Array.isArray(items) &&
      items.length % 3 === 0) {
      setMultipleOf(3);
      return;
    }
    if (Array.isArray(items) &&
      items.length % 2 === 0) {
      setMultipleOf(2);
      return;
    }
  }, [items]);
  const handleNumberInput = (e, onChange) => {
    const value = e.target.value;
    if (value === '' || /^-?\d*\.?\d*$/.test(value)) {
      onChange(e);
    }
  };

  if (isArray) {
    return (
      <FieldArray name={options.name}>
        {({ push, remove }) => (
          <div>
            {values[options.name].map((material, index) => {
              const itemsErrors = errors[options.name] && errors[options.name][index] ? errors[options.name][index] : {};
              return (
                <div key={index}>
                  <Row>
                    {items.map((item, idx) => {
                      const isDecimalInput = item.typeOfInput === "text" && item.inputMode === "decimal";
                      const fieldName = `${options.name}[${index}].${item.name}`;
                      return (
                        <Col md={12 / multipleOf} key={idx}>
                          <FormGroup>
                            <Label
                              className="form-control-label font-weight-light"
                              htmlFor={fieldName}
                            >
                              {item.label}
                              {item.required && (
                                <sup className={itemsErrors[item.name] ? "text-danger" : ""}>
                                  *
                                </sup>
                              )}
                            </Label>
                            {typeof item.typeOfInput !== 'string' ? (
                              <Field name={fieldName}>
                              {({ field, form}) => (
                                <>
                                 <item.typeOfInput
                                 type={type}
                                 value={field.value}
                                 form={form}
                                  required={item.required}
                                  name={fieldName}
                                  placeholder={item.placeholder}
                                  className={`form-control text-dark ${itemsErrors[item.name] ? "is-invalid" : ""}`}
                                  inputMode={item.inputMode || undefined}
                                  readOnly={item.readOnly || false}
                                  onChange={item.onChange ? (e) => item.onChange(e, formik, `${options.name}[${index}]`) : formik.handleChange}
                                />
                                </>
                              )}
                            </Field>
                             
                            ) : item.typeOfInput === "select" ? (
                              <Field
                                type={type}
                                as="select"
                                required={item.required}
                                name={fieldName}
                                className={`form-control text-dark ${itemsErrors[item.name] ? "is-invalid" : ""}`}
                                placeholder={item.placeholder}
                                inputMode={item.inputMode || undefined}
                                readOnly={item.readOnly || false}
                                onChange={item.onChange ? (e) => item.onChange(e, formik, `${options.name}[${index}]`) : formik.handleChange}
                                defaultValue=""
                              >
                                <option value="" disabled >Choose one</option>
                                {item.options && item.options.map((option) => (
                                  <option key={option.value} value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </Field>
                            ) : (
                              <Field
                                required={item.required}
                                name={fieldName}
                                className={`form-control text-dark ${itemsErrors[item.name] ? "is-invalid" : ""}`}
                                placeholder={item.placeholder}
                                type={type || item.typeOfInput || "text"}
                                inputMode={item.inputMode || undefined}
                                readOnly={item.readOnly || false}
                                onChange={isDecimalInput
                                  ? (e) => handleNumberInput(e, item.onChange ? (event) => item.onChange(event, formik, `${options.name}[${index}]`) : formik.handleChange)
                                  : (item.onChange ? (e) => item.onChange(e, formik, `${options.name}[${index}]`) : formik.handleChange)
                                }
                                // onChange={item.onChange ? (e) => item.onChange(e, formik, `${options.name}[${index}]`) : formik.handleChange}
                              />
                            )}
                            <ErrorMessage
                              name={fieldName}
                              component="div"
                              className="text-danger"
                            />
                          </FormGroup>
                        </Col>
                      );
                    })}
                  </Row>
                  <Col md="12">
                    <Button
                      className="m-auto d-flex"
                      type="button"
                      color="danger"
                      onClick={() => remove(index)}
                      disabled={values.materials.length === 1}
                    >
                      Remove
                    </Button>
                  </Col>
                  <Col md="12">
                    <hr className="my-2" />
                  </Col>
                </div>
              );
            })}
            <Col md="12">
              <Button
                size="sm"
                color="link"
                type="button"
                className="icon-sm m-auto d-flex"
                onClick={() => push(defaultValues)}
              >
                <i className="ni ni-fat-add" />
              </Button>
            </Col>
          </div>
        )}
      </FieldArray>
    );
  }

  return (
    <Row>
      <Row md="12">
        {Array.isArray(items) &&
          items.map((item, index) => {
            const fieldOnChange = item.onChange
              ? (e) => item.onChange(e, formik)
              : formik.handleChange;

            return (
              <Col md={12 / multipleOf} key={index}>
                <FormGroup>
                  <Label
                    className="form-control-label font-weight-light"
                    id={`${item.name}Label`}
                  >
                    {item.label}
                    {item.required && (
                      <sup
                        className={
                          errors?.[item.name] ? "text-danger" : ""
                        }
                      >
                        *
                      </sup>
                    )}
                  </Label>
                  {item.typeOfInput === "select" ? (
                    <Field
                      as="select"
                      required={item.required}
                      name={item.name}
                      className={`form-control text-dark ${errors?.[item.name] ? "is-invalid" : ""}`}
                      placeholder={item.placeholder}
                    >
                      <option value="" disabled hidden>
                        Choose one
                      </option>
                      {item.options.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Field>
                  ) : (
                    <Field
                      required={item.required}
                      name={item.name}
                      className={`form-control text-dark ${errors?.[item.name] ? "is-invalid" : ""}`}
                      placeholder={item.placeholder}
                      type={item.typeOfInput || "text"}
                      inputMode={item.inputMode || undefined}
                      readOnly={item.readOnly || false}
                      onChange={fieldOnChange}
                    />
                  )}
                  <ErrorMessage
                    name={item.name}
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>
            );
          })}
      </Row>
      <Col md="12">
        <hr className="my-2" />
      </Col>
      {/* <Col md="12">
        <Button
          size="sm"
          color="link"
          type="button"
          className="icon-sm m-auto d-flex"
        >
          <i className="ni ni-fat-add" />
        </Button>
      </Col> */}
    </Row>
  );
};

export const CreateGenericFormPartHorizontal = (options) => {
  return (props) => <GenericFormPartHorizontal {...props} {...options} options={options} />;
};