/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {
  Card,
  CardBody,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  Row,
} from "reactstrap";
import { useFirebase } from "model/context/firebase.context";
import { COLLECTIONS } from "model/constants";
import slugify from "slugify";
import { MaterialsModal } from "components/Suppliers/MaterialsModal";

function MaterialsView() {
  const { db } = useFirebase();
  const [search, setSearch]= useState('');
  const [items, setItems] = useState([]);
  const [trades, setTrades] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const unsubscribeTrades = db.listen(COLLECTIONS.trades, (data) => {
      if (data) {
        setTrades(Object.values(data));
      } else {
        setTrades([]);
      }
    });
    const unsubscribeSuppliers = db.listen(COLLECTIONS.suppliers, (data) => {
      if (data) {
        const keys = Object.keys(data)
        const items = keys.reduce((result, key) => {
          const item = data[key];
          result.push({
            ...item,
            id: key,
          })
          return result;
        }, []);
        setSuppliers(items);
      } else {
        setSuppliers([]);
      }
    })
    const unsubscribe = db.listen(COLLECTIONS.materials, (data) => {
      if (data) {
        const keys = Object.keys(data)
        const items = keys.reduce((result, key) => {
          const item = data[key];
          result.push({
            ...item,
            id: key,
          })
          return result;
        }, []);
        setItems(items);
      } else {
        setItems([]);
      }
      if (loading) setLoading(false);
    });
    return () => {
      unsubscribe();
      unsubscribeTrades();
      unsubscribeSuppliers();
    }
  }, []);
  const getCost = (suppliers) => {
    const sorted = [...suppliers].sort((a, b) => a.cost < b.cost ? -1 : 1);
    const first = sorted.shift()?.cost;
    const last = sorted.pop()?.cost;
    return (
      ((first && !last) || (first === last)) ? first : `${first} - ${last}`
    );
  }
  useEffect(() => {
    if (!openAddModal && selectedItem) {
      setTimeout(() => {
        setSelectedItem(null);
      }, (350));
    }
  }, [openAddModal]);
  useEffect(() => {
    if (selectedItem) setOpenAddModal(true);
  }, [selectedItem]);
  return (
    <>
      <SimpleHeader
        search={setSearch}
        actions={[
          {
              label: 'Add Supply / Material',
              handler: () => setOpenAddModal(true)
          }
        ]}
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col xs="12">
            <Card>
                <CardBody>
                    <ListGroup flush>
                      <ListGroupItemHeading>
                        <Row>
                          <Col lg="4" sm="9" md="6" xs="10">Name</Col>
                          <Col lg="2" sm="2" md="3" className="d-none d-sm-block">Trade</Col>
                          <Col lg="2" sm="4" md="2" className="d-none d-md-block">Cost</Col>
                          <Col lg="2" sm="4" className="d-none d-lg-block">Price</Col>
                          <Col lg="1" sm="4" className="d-none d-lg-block">Tax</Col>
                          <Col lg="1" sm="1" md="1" xs="2"></Col>
                        </Row>
                      </ListGroupItemHeading>
                      {!items.length && (
                        <ListGroupItem>
                            <p className="text-center">{loading ? 'Loading...' : 'There are no items yet'}</p>
                        </ListGroupItem>
                      )}
                      {(search ? items.filter(item => {
                        const opts = { lower: true, replacement: ' ' };
                        const name = slugify(item.name, opts);
                        const trade = slugify(item.trade || '', opts);
                        return name.includes(search) || trade.includes(search);
                      }) : items).sort((a, b) => new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1).map(item => (
                        <ListGroupItem key={item.id} action onClick={() => setSelectedItem(item)}>
                          <Row>
                            <Col lg="4" sm="9" md="6" xs="10" className="text-nowrap text-truncate">{item.name}</Col>
                            <Col lg="2" sm="2" md="3" className="d-none d-sm-block text-nowrap text-truncate">{item.trade}</Col>
                            <Col lg="2" sm="4" md="2" className="d-none d-md-block text-nowrap text-truncate">{getCost(item.suppliers)}</Col>
                            <Col lg="2" sm="4" className="d-none d-lg-block text-nowrap text-truncate">{item.price}</Col>
                            <Col lg="1" sm="4" className="d-none d-lg-block text-nowrap text-truncate">{item.tax}</Col>
                            <Col lg="1" sm="1" md="1" xs="2" className="d-flex flex-row flex-nowrap">
                              <i className="ni ni-bold-right" />
                            </Col>
                          </Row>
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      
      <MaterialsModal
        suppliers={suppliers} 
        materials={items}
        trades={trades}
        open={openAddModal}
        onClose={() => setOpenAddModal(false)}
        selectedItem={selectedItem}
      />
    </>
  );
}

export default MaterialsView;
