import React from 'react';
import { Spinner } from 'reactstrap';

export function LoaderSpinner () {
    return (
        <>
            <div className="backdrop">
                <Spinner
                    style={{
                        position: 'fixed',
                        top: 'calc(50% - 2rem)',
                        left: 'calc(50% - 2rem)'
                    }}
                />
            </div>
        </>
    );
}