import React, { useState } from "react";
import { Button, Card, CardHeader, CardBody } from "reactstrap";
import { CustomerNoteModal } from "./CustomerNoteModal";

import moment from "moment";

export function CustomerNotesTimeline({
  internal,
  notes,
  setError,
  entityId,
  entityType,
}) {
  const [openModal, setModalOpen] = useState(false);
  const [editNoteId, setEditNoteId] = useState(null);

  return (
    <>
      <Card className="h-100 no-print">
        <CardHeader>
          <div className="d-flex">
            <h3>Notes Timeline</h3>
            <Button
              size="sm"
              color="link"
              type="button"
              className="ml-auto text-nowrap"
              onClick={() => {
                setModalOpen(true);
              }}
            >
              Add note
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          {notes.length > 0 ? (
            <div
              className="timeline timeline-one-side"
              data-timeline-axis-style="dashed"
              data-timeline-content="axis"
            >
              {notes.map((note) => (
                <div key={note.id} className="timeline-block">
                  <span className="timeline-step badge-primary">
                    <i className="ni ni-chat-round" />
                  </span>
                  <div key={note.id} className="timeline-content">
                    <small className="text-muted font-weight-bold">
                      {note.userName || "Unknown User"} -{" "}
                      {moment(note.createdAt).format("MMM DD, YYYY")}
                    </small>
                    <Button
                      size="sm"
                      color="link"
                      type="button"
                      className="ml-auto text-nowrap"
                      onClick={() => {
                        setEditNoteId(note); // Set the note ID
                        setModalOpen(true);
                      }}
                    >
                      Edit
                    </Button>

                    <h5 className="mt-3 mb-0">{note.value} </h5>
                    <p
                      className="text-sm mt-1 mb-0"
                      dangerouslySetInnerHTML={{
                        __html: note.text?.replace(/\r|\n/, "<br />"),
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-center">There're no notes.</p>
          )}
        </CardBody>
      </Card>
      {console.log("Passing editNoteId to CustomerNoteModal:", editNoteId)}
      <CustomerNoteModal
        newNoteNumber={notes.length}
        internal={internal}
        entityId={entityId}
        entityType={entityType}
        openModal={openModal}
        setModalOpen={setModalOpen}
        setError={setError}
        existigNoteId={editNoteId}
      />
    </>
  );
}

export default CustomerNotesTimeline;
