export const config = {
    development: process.env.NODE_ENV === 'development',
    domain: process.env.REACT_APP_DOMAIN,
    firebaseApiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    firebaseAuthDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    firebaseProjectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    firebaseStorageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    firebaseMessagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    firebaseAppId: process.env.REACT_APP_FIREBASE_APPID,
    firebaseMeasurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    firebaseGoogleProviderScope: process.env.REACT_APP_FIREBASE_GOOGLE_PROVIDER_SCOPE,
};