/*!

=========================================================
* Argon Dashboard PRO React - v1.2.5
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link, NavLink } from "react-router-dom";

// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";
import { defaultRoute } from "routes";

function AlternativeHeader({ current, parent, actions }) {
  const renderActions = actions?.map((action) => (
    <Button key={action.label} className={action.color ? '' : "btn-neutral"} color={action.color || "default"} size="sm" onClick={action.handler}>
      {action.label}
    </Button>
  ));
  return (
    <>
      <div className="header pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col sm="6" xs="12">
                <Breadcrumb
                  className="d-none d-md-inline-block"
                  listClassName="breadcrumb-links"
                >
                  <BreadcrumbItem>
                    <NavLink to={defaultRoute} tag={Link}>
                      <i className="fas fa-home" />
                    </NavLink>
                  </BreadcrumbItem>
                  {!!parent?.name && (
                  <BreadcrumbItem className="text-capitalize">
                    <NavLink to={`/admin${parent.route}`} tag={Link}>
                      {parent.name}
                    </NavLink>
                  </BreadcrumbItem>
                  )}
                  {!!current?.name && (
                    <BreadcrumbItem aria-current="page" className="active text-capitalize">
                      {current.route ? (
                          <NavLink to={`/admin${current.route}`} tag={Link}>
                            {current.name}
                          </NavLink>
                        ) : current.name}
                    </BreadcrumbItem>
                  )}
                </Breadcrumb>
              </Col>
              <Col className="text-right" sm="6" xs="12">
                {renderActions}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default AlternativeHeader;
