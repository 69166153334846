/* eslint-disable react-hooks/exhaustive-deps */
/*!

=========================================================
* Argon Dashboard PRO React - v1.2.5
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useMemo } from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Navbar,
  NavItem,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useAuth } from "model/context/auth.context";
import { Link, NavLink, useLocation } from "react-router-dom";
import routes from "routes";

function AdminNavbar({ theme, sidenavOpen, toggleSidenav }) {
  const { logout, session: { user } } = useAuth();
  const { pathname } = useLocation();
  const getRoutes = (routes, redirect = false) => routes.map((prop) => {
    if (prop.redirect && !redirect) return null;
    if (prop.collapse) return getRoutes(prop.views);
    return (prop.layout === "/admin") ? prop : null;
  }).filter(route => !!route).flat();
  const Heading = useMemo(() => {
    const rawRoutes = getRoutes(routes, true);
    return rawRoutes.find((route) => new RegExp(`${route.path}/?(:.*)?`).test(pathname.replace('/admin', '')))?.name || '';
  }, [pathname]);
  const Shortcuts = useMemo(() => {
    const rawRoutes = getRoutes(routes).filter(prop => prop.shortcut !== false);
    return rawRoutes.map((route) => (
      <Col
        key={route.path}
        className="shortcut-item"
        xs="4"
      >
        <NavLink to={`/admin${route.path}`} tag={Link}>
          <span className="shortcut-media avatar rounded-circle bg-gradient-info">
            <i className={(route.icon || route.shortcutIcon)?.replace('text-default', 'text-white')} />
          </span>
          <small className="text-truncate">{route.shortcutName || route.name}</small>
        </NavLink>
      </Col>
    ));
  }, [pathname]);
  return (
    <>
      <Navbar
        className={classnames(
          "navbar-top navbar-expand border-bottom",
          { "navbar-dark bg-info": theme === "dark" },
          { "navbar-light bg-secondary": theme === "light" }
        )}
      >
        <Container fluid>
          <Collapse navbar isOpen={true}>
            <h1
              className="h1 text-white d-inline-block mb-0 mr-4"
            >
              {Heading}
            </h1>

            <Nav className="align-items-center ml-md-auto" navbar>
              <NavItem className="d-xl-none">
                <div
                  className={classnames(
                    "pr-3 sidenav-toggler",
                    { active: sidenavOpen },
                    { "sidenav-toggler-dark": theme === "dark" }
                  )}
                  onClick={toggleSidenav}
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </div>
              </NavItem>
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link" color="" tag="a">
                  <i className="ni ni-ungroup" />
                </DropdownToggle>
                <DropdownMenu
                  className="dropdown-menu-lg dropdown-menu-dark bg-default"
                  right
                >
                  <Row className="shortcuts px-4">
                    {Shortcuts}
                  </Row>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
            <Nav className="align-items-center ml-auto ml-md-0" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link pr-0" color="" tag="a">
                  <Media className="align-items-center">
                    {!!user?.photoURL && (
                      <span className="avatar avatar-sm rounded-circle">
                        <img
                          alt="..."
                          referrerPolicy="no-referrer"
                          src={user.photoURL}
                        />
                      </span>
                    )}
                    {!!user?.displayName && (
                      <Media className="ml-2 d-none d-lg-block">
                        <span className="mb-0 text-sm font-weight-bold">
                          {user.displayName}
                        </span>
                      </Media>
                    )}
                  </Media>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    onClick={() => logout()}
                  >
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => { },
  sidenavOpen: false,
  theme: "dark",
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

export default AdminNavbar;
