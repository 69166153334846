/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  Row,
} from "reactstrap";
import { SubcontractorModal } from "components/Contacts/SubcontractorModal";
import { useFirebase } from "model/context/firebase.context";
import { COLLECTIONS } from "model/constants";
import slugify from "slugify";

function SubcontractorsView() {
  const { db } = useFirebase();
  const [search, setSearch]= useState('');
  const [items, setItems] = useState([]);
  const [activities, setActivities] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const unsubscribeActivities = db.listen(COLLECTIONS.activities, (data) => {
      if (data) {
        const keys = Object.keys(data)
        const items = keys.reduce((result, key) => {
          const item = data[key];
          result.push({
            ...item,
            id: key,
          })
          return result;
        }, []);
        setActivities(items);
      } else {
        setActivities([]);
      }
    })

    const unsubscribe = db.listen(COLLECTIONS.subcontractors, (data) => {
      if (data) {
        const keys = Object.keys(data)
        const items = keys.reduce((result, key) => {
          const item = data[key];
          result.push({
            ...item,
            id: key,
          })
          return result;
        }, []);
        setItems(items);
      } else {
        setItems([]);
      }
      if (loading) setLoading(false);
    });
    return () => {
      unsubscribe();
      unsubscribeActivities();
    }
  }, []);

  useEffect(() => {
    if (!openAddModal && selectedItem) {
      setTimeout(() => {
        setSelectedItem(null);
      }, (350));
    }
  }, [openAddModal]);
  
  return (
    <>
      <SimpleHeader
        search={setSearch}
        actions={[
          {
              label: 'Add Subcontractor',
              handler: () => setOpenAddModal(true)
          }
        ]}
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col xs="4">
            <Card>
                <CardBody>
                    <ListGroup flush>
                      <ListGroupItemHeading>
                        <Row>
                          <Col lg="4" md="6" sm="6" xs="10" >Name</Col>
                          <Col lg="4" className="d-none d-lg-block">Phone</Col>
                          <Col lg="3" sm="4" className="d-none d-sm-block">trade</Col>
                          <Col lg="1" md="1" sm="2" xs="2" ></Col>
                        </Row>
                      </ListGroupItemHeading>
                      {!items.length && (
                        <ListGroupItem>
                            <p className="text-center">{loading ? 'Loading...' : 'There are no items yet'}</p>
                        </ListGroupItem>
                      )}
                      {(search ? items.filter(item => {
                        const opts = { lower: true, replacement: ' ' };
                        const name = slugify(item.name || '', opts);
                        const trade = slugify(item.trade || '', opts);
                        return name.includes(search) || trade.includes(search);
                      }) : items).sort((a, b) => new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1).map(item => (
                        <ListGroupItem key={item.id} action onClick={() => setSelectedItem(item)}>
                          <Row>
                            <Col lg="4" md="6" sm="6" xs="10" className="text-bold text-nowrap text-truncate">
                              {item.name}
                            </Col>
                            <Col lg="4" className="d-none d-lg-block text-nowrap text-truncate">
                              {item.mobile || item.phone}
                            </Col>
                            <Col lg="3" sm="4" className="d-none d-sm-block text-nowrap text-truncate">
                              {item.trade}
                            </Col>
                            <Col lg="1" md="1" sm="2" xs="2" className="d-flex flex-row flex-nowrap">
                              <i className="ni ni-bold-right" />
                            </Col>
                          </Row>
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                </CardBody>
            </Card>
          </Col>
          <Col xs="8">
          <Card>
              <CardBody>
                <div>
                  {selectedItem ? (
                    <div>
                      <h3>{selectedItem.name}<Button size="sm" color="link" onClick={() => setOpenAddModal(true)}>Edit</Button></h3>
                      <p><strong>Email:</strong> {selectedItem.email}</p>
                      <p><strong>Phone:</strong> {selectedItem.mobile || selectedItem.phone}</p>
                      <p><strong>City, State:</strong> {selectedItem.city}, {selectedItem.state}</p>
                      <p><strong>Created At:</strong> {new Date(selectedItem.createdAt).toLocaleString()}</p>
                      {/* Add more fields as necessary */}
                    </div>
                  ) : (
                    <p>Select a lead to see details</p>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <SubcontractorModal 
        activities={activities} 
        open={openAddModal}
        onClose={() => setOpenAddModal(false)}
        selectedItem={selectedItem}
      />
    </>
  );
}

export default SubcontractorsView;
