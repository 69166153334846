export const COLLECTIONS = {
    leads: '/leads',
    customers: '/customers',
    subcontractors: '/subcontractors',
    suppliers: '/suppliers',
    materials: '/materials',
    projects: '/projects',
    projectComments: '/project-comments',
    activities: '/activities',
    trades: '/trades',
    projectTrades: '/project-trades',
    customerNotes: '/customer-notes',
    projectNotes: '/project-notes',
};