import { nanoid } from "nanoid";

export const getIsMac = () => {
  return navigator.platform.toUpperCase().indexOf('MAC') >= 0;
};

export const getNestedValue = (obj, path) => {
  return path.split('.').reduce((accumulator, key) => {
    const match = key.match(/(\w+)\[(\d+)\]/);
    if (match) {
      const arrayKey = match[1];
      const index = parseInt(match[2], 10);
      return accumulator && accumulator[arrayKey] && accumulator[arrayKey][index] !== undefined
        ? accumulator[arrayKey][index]
        : undefined;
    }
    return accumulator && accumulator[key] !== undefined ? accumulator[key] : undefined;
  }, obj);
};

export const transformData = (data) => {
  if (data) {
    const keys = Object.keys(data);
    const items = keys.reduce((result, key) => {
      const item = data[key];
      result.push({
        ...item,
        id: key,
      });
      return result;
    }, []);
    if(items.length > 0 && items[0].name){
      return items.sort((a, b) => a.name.localeCompare(b.name));
    }
    return items;
  } else {
    return [];
  }
};

// Debounce function
export const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

export const generateNanoId = () => {
  return nanoid();
};

export const handleNumberInput = (e) => {
  const value = e.target.value.replace(/[^0-9]/g, '');
  e.target.value = value;
};