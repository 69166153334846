// components/Contacts/FilterModal.jsx
import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
} from "reactstrap";

export const FilterModal = ({
  open,
  toggle,
  filters,
  setFilters,
  applyFilters,
}) => {
  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      className="modal-dialog-centered modal-lg modal-white"
    >
      <div className="p-3">
        <ModalHeader toggle={toggle} className="border-bottom-0">
          Filter Leads
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label className="form-control-label font-weight-light">
                  Is Visited
                </Label>
                <Input
                  type="select"
                  value={filters.isVisited ?? ""}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      isVisited: e.target.value || null,
                    })
                  }
                  className="form-control text-dark"
                >
                  <option value="">Any</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label className="form-control-label font-weight-light">
                  Is Info Confirmed
                </Label>
                <Input
                  type="select"
                  value={filters.isInfoConfirmed ?? ""}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      isInfoConfirmed: e.target.value || null,
                    })
                  }
                  className="form-control text-dark"
                >
                  <option value="">Any</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label className="form-control-label font-weight-light">
                  Is Bid Sent
                </Label>
                <Input
                  type="select"
                  value={filters.isbidSent ?? ""}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      isbidSent: e.target.value || null,
                    })
                  }
                  className="form-control text-dark"
                >
                  <option value="">Any</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label className="form-control-label font-weight-light">
                  Has Basement Finished
                </Label>
                <Input
                  type="select"
                  value={filters.hasBasementFinished ?? ""}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      hasBasementFinished: e.target.value || null,
                    })
                  }
                  className="form-control text-dark"
                >
                  <option value="">Any</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="border-top-0">
          <Button color="link" type="button" onClick={toggle}>
            Cancel
          </Button>
          <Button color="primary" type="button" onClick={applyFilters}>
            Apply Filters
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};
