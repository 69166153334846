/* eslint-disable react-hooks/exhaustive-deps */

import { COLLECTIONS } from "model/constants";
import { useFirebase } from "model/context/firebase.context";
import React, { forwardRef, useEffect, useState } from "react";
import { useRef } from "react";
import { FormGroup, Input } from "reactstrap";
import { generateNanoId } from "utils";
import { transformData } from "utils";

// Note: This component has to be self contained at this point
const SupplierNameInput = forwardRef(({ supplier, index, onChange, onClick, onBlur, ...props }, ref) => {
	const { db } = useFirebase();
	const [supplierName, setSupplierName] = useState(props.value || '');
	const [filteredSuppliers, setFilteredSuppliers] = useState([]);
	const [, setShowList] = useState(false);
	const inputRef = useRef(null);
	const [suppliers, setSuppliers] = useState([]);
	// const [supplierId, setSupplierId] = useState(supplier?.id || "");
	const uid = generateNanoId()
	useEffect(() => {
		const unsubscribeSuppliers = db.listen(COLLECTIONS.suppliers, (data) => {
			const items = transformData(data);
			setSuppliers(items);
			setFilteredSuppliers(items);
		});
		return () => {
			unsubscribeSuppliers();
		}
	}, []);

	const addSupplier = async (supplier) => {
		const supplierExists = suppliers.some(item => item.name === supplier);
		if (!supplierExists) {
			await db.insert(COLLECTIONS.suppliers, { name: supplier });
		}
	}

	const handleInputClick = (e) => {
		// Show the list when the input is clicked
		if (supplierName) {
			const filtered = suppliers.filter((supplier) =>
				supplier.name.toLowerCase().includes(supplierName.toLowerCase())
			);
			setFilteredSuppliers(filtered);
		} else {
			setFilteredSuppliers(suppliers);
		}
		setShowList(true);
		onClick && onClick(e);
	};

	const handleInputBlur = (e) => {
		// Delay hiding the list to allow click event on list items
		setTimeout(() => {
			setShowList(false);
		}, 100);

		// If the supplier name is not in the list, add it to Firebase
		if (supplierName && !suppliers.includes(supplierName)) {
			// Add the new supplier to Firebase
			addSupplier(supplierName);
		}
		onBlur && onBlur(e);
	};

	// Handle input change
	const handleInputChange = (e) => {
		const value = e.target.value;
		setSupplierName(value);

		// Filter suppliers based on input
		if (value.length > 0) {
			const filtered = suppliers.filter((supplier) =>
				supplier.name && supplier.name.toLowerCase().includes(value.toLowerCase())
			);
			setFilteredSuppliers(filtered);
		} else {
			setFilteredSuppliers(suppliers);
		}
		setShowList(true);
		onChange && onChange(e);
	};
	return (
		<FormGroup>
			<div style={{ position: 'relative' }}>
				<Input
					{...props}
					ref={inputRef}
					value={supplierName}
					className={`form-control text-dark`}
					onChange={handleInputChange}
					onClick={handleInputClick}
					onBlur={handleInputBlur}
					autoComplete="off"
					list={`${uid}-suppliers`}
				/>
				<datalist id={`${uid}-suppliers`}>
					{filteredSuppliers.map((supplier, index) => (
						<option
							key={index}
								value={supplier.name}
							>
								{supplier.name}
							</option>
						))}
					</datalist>
			</div>
		</FormGroup>
	);
});


export default SupplierNameInput;