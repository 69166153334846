/*!

=========================================================
* Argon Dashboard PRO React - v1.2.5
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  CardHeader,
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import { Link, NavLink } from "react-router-dom";
import { useAuth } from "model/context/auth.context";

function Lock() {
  const { recoverPassword } = useAuth();
  const [focused, setFocused] = React.useState(false);
  return (
    <>
      <AuthHeader title="Lock screen" lead="Better to be safe than sorry." />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="mb-0 card-profile bg-white mt-5">
              <CardHeader>
                <div className="text-center text-muted">
                  <small>Enter your email address and press the button below to recover your password.</small>
                </div>
              </CardHeader>
              <CardBody className="pt-4 px-5">
                <Form role="form" onSubmit={(e) => {
                  e.preventDefault();
                  const form = e.target;
                  const email = form.elements['email'].value;
                  recoverPassword(email)
                }}>
                  <FormGroup
                    className={classnames({
                      focused: focused,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText className="bg-lighter text-dark">
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="bg-lighter text-dark"
                        name="email"
                        placeholder="Email"
                        type="email"
                        onFocus={() => setFocused(true)}
                        onBlur={() => setFocused(false)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button className="mt-2" color="info" type="submit">
                      Recover password
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
            <Row className="mt-3">
              <Col xs="12" className="text-center">
                <NavLink
                  tag={Link}
                  className="text-light"  
                  to="/auth/login"
                >
                  <small>Back to Sign in</small>
                </NavLink>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Lock;
