import { useFormikContext } from "formik";
import { useFirebase } from "model/context/firebase.context";
import { useEffect, useState } from "react";
import { Button, Col, FormGroup, Input, ListGroup, ListGroupItem, Row } from "reactstrap";
import { transformData } from "utils";

const CustomNote = ({  errors, selectedItem, options, ...props }) => {
	const { collection = null, defaultValues, name } = options;
	const { db } = useFirebase();
	const [notes, setNotes] = useState(selectedItem?.notes || []);
	const [noteValue, setNoteValue] = useState('');
	const { values, setFieldValue } = useFormikContext();
	useEffect(() => {
		let unsubscribeNotes;
		const loadDB = () => {
			const notes = values[name];
			if (collection && notes && notes.length > 0) {
				unsubscribeNotes = db.listen(collection, (data) => {
					//We only want the notes from this item
					const items = transformData(data);
					if(items){
						const filteredItems = items.filter(item => notes.some(defaultValue => defaultValue.noteId === item.id));
						if (filteredItems) {
							setNotes(filteredItems);
						}
					}
				});
				setNotes(defaultValues);
			}
		}
		loadDB();
		return () => {
			unsubscribeNotes && unsubscribeNotes();
		}
	}, [defaultValues, collection, db, name, values])

	const renderNotes = notes.sort((a, b) => new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1).map(note => (
		<>
		{note && <ListGroupItem key={note.createdAt}>
			<div className="text-light text-xs float-right">{new Date(note.createdAt).toDateString()}</div>
			{note.value && <div className="pt-4" dangerouslySetInnerHTML={{ __html: note.value.replace(/\r|\n/gi, '<br/>') }} />}
		</ListGroupItem>}
		</>
	));

	const handleAddNote = async () => {
		if (noteValue) {
			const currentNotes = values[name];
			const noteId = await db.insert(collection, {
				createdAt: new Date().toISOString(),
				value: noteValue
			})
			const newNotes = [...currentNotes, {
				noteId,
			}];
			setFieldValue(name, newNotes);
			setNotes([
				...notes,
				{
					createdAt: new Date().toISOString(),
					value: noteValue
				}
			]);
			setNoteValue('');
		}
	};

	return (
		<>
			{collection ? <Row>
				<Col md="6" className="h-100">
					<FormGroup className="h-100">
						<div className="d-flex flex-row justify-content-between">
							<label className="form-control-label font-weight-light">New Note <sup className={!!errors?.note ? 'text-danger' : ''}>{errors.note}</sup></label>
							<Button size="sm" color="link" type="button" onClick={handleAddNote}>Add Note</Button>
						</div>
						<Input
							name="note"
							className={`form-control text-dark h-100 ${errors?.note ? 'is-invalid' : ''}`}
							placeholder="Note won't be added to the list until the 'Add Note' button above is pressed."
							type="textarea"
							style={{
								minHeight: 270,
								resize: 'none'
							}}
							value={noteValue}
							onChange={(e) => setNoteValue(e.target.value)}
						/>
					</FormGroup>
				</Col>
				<Col md="6">
					<ListGroup flush className="pre-scrollable d-none d-md-block">
						{notes && renderNotes}
					</ListGroup>
					<ListGroup flush className="d-md-none d-block">
						{notes && renderNotes}
					</ListGroup>
				</Col>
			</Row>
				: <>INVALID COLLECTION</>}
		</>

	)
}
export default CustomNote;

export const CreateCustomNotes = (options) => {
	return (props) => <CustomNote {...props} {...options} options={options} />;
};