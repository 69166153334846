/* eslint-disable react-hooks/exhaustive-deps */

import { COLLECTIONS } from "model/constants";
import { useFirebase } from "model/context/firebase.context";
import React, { forwardRef, useEffect, useState } from "react";
import { useRef } from "react";
import { FormGroup, Input } from "reactstrap";
import { generateNanoId } from "utils";
import { transformData } from "utils";

// Note: This component has to be self contained at this point
const SubContractorNameInput = forwardRef(({ subContractor, index, onChange, onClick, onBlur, field, value, ...props }, ref) => {
	const { db } = useFirebase();
	const [subContractorName, setSubContractorName] = useState(props.value || '');
	const [filteredSubContractors, setFilteredSubContractors] = useState([]);
	const [, setShowList] = useState(false);
	const inputRef = useRef(null);
	const [subContractors, setSubContractors] = useState([]);
	// const [msg, setMessage] = useState();
	// const [subContractorId, setSubContractorId] = useState(subContractor?.id || "");
	const uid = generateNanoId()
	useEffect(() => {
		const subCollections = COLLECTIONS.subcontractors;
		const unsubscribeSubContractors = db.listen(subCollections, (data) => {
			const items = transformData(data);
			setSubContractors(items);
			setFilteredSubContractors(items);
		});
		return () => {
			unsubscribeSubContractors();
		}
	}, []);

	useEffect(() => {
    setSubContractorName(value || '');
  }, [value]);

	const addSubContractor = async (subContractor) => {
		const subContractorExists = subContractors.some(item => item.name === subContractor);
		if (!subContractorExists) {
			await db.insert(COLLECTIONS.subcontractors, { name: subContractor });
		}
	}

	const handleInputClick = (e) => {
		// Show the list when the input is clicked
		if (subContractorName) {
			// const filtered = subContractors.filter((subContractor) =>
			// 	subContractor.name.toLowerCase().includes(subContractorName.toLowerCase())
			// );
			// setFilteredSubContractors(filtered);
		} else {
			setFilteredSubContractors(subContractors);
		}
		setShowList(true);
		onClick && onClick(e);
	};

	const handleInputBlur = (e) => {
		// Delay hiding the list to allow click event on list items
		setTimeout(() => {
			setShowList(false);
		}, 100);

		// If the subContractor name is not in the list, add it to Firebase
		if (subContractorName && !subContractors.includes(subContractorName)) {
			// Add the new subContractor to Firebase
			addSubContractor(subContractorName);
		}
		onBlur && onBlur(e);
	};

	// Handle input change
	const handleInputChange = (e) => {
		const value = e.target.value;
		setSubContractorName(value);

		// Filter subContractors based on input
		if (value.length > 0) {
			const filtered = subContractors.filter((subContractor) =>
				subContractor.name && subContractor.name.toLowerCase().includes(value.toLowerCase())
			);
			setFilteredSubContractors(filtered);
		} else {
			setFilteredSubContractors(subContractors);
		}
		setShowList(true);
		onChange && onChange(e);
	};

	return (
		<FormGroup>
			<div style={{ position: 'relative' }}>
				<Input
					{...props}
					ref={inputRef}
					value={subContractorName}
					className={`form-control text-dark`}
					onChange={handleInputChange}
					onClick={handleInputClick}
					onBlur={handleInputBlur}
					autoComplete="off"
					list={`${uid}-subContractors`}
				/>
				<datalist id={`${uid}-subContractors`}>
					{filteredSubContractors.map((subContractor, index) => (
						<option
							key={index}
								value={subContractor.name}
							>
								{subContractor.name}
							</option>
						))}
					</datalist>
			</div>
		</FormGroup>
	);
});


export default SubContractorNameInput;