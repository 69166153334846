/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {
  Card,
  CardBody,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  Row,
} from "reactstrap";
import { useFirebase } from "model/context/firebase.context";
import { COLLECTIONS } from "model/constants";
import slugify from "slugify";
import { SuppliersModal } from "components/Suppliers/SuppliersModal";

function SuppliersView() {
  const { db } = useFirebase();
  const [search, setSearch]= useState('');
  const [items, setItems] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const unsubscribe = db.listen(COLLECTIONS.suppliers, (data) => {
      if (data) {
        const keys = Object.keys(data)
        const items = keys.reduce((result, key) => {
          const item = data[key];
          result.push({
            ...item,
            id: key,
          })
          return result;
        }, []);
        setItems(items);
      } else {
        setItems([]);
      }
      if (loading) setLoading(false);
    });
    return () => {
      unsubscribe();
    }
  }, []);
  useEffect(() => {
    if (!openAddModal && selectedItem) {
      setTimeout(() => {
        setSelectedItem(null);
      }, (350));
    }
  }, [openAddModal]);
  useEffect(() => {
    if (selectedItem) setOpenAddModal(true);
  }, [selectedItem]);
  return (
    <>
      <SimpleHeader
        search={setSearch}
        actions={[
          {
              label: 'Add Supplier',
              handler: () => setOpenAddModal(true)
          }
        ]}
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col xs="12">
            <Card>
                <CardBody>
                    <ListGroup flush>
                      <ListGroupItemHeading>
                        <Row>
                          <Col lg="4" md="6" sm="6" xs="10" >Company</Col>
                          <Col lg="3" className="d-none d-lg-block">Phone</Col>
                          <Col lg="4" md="5" sm="4" className="d-none d-sm-block">City, State</Col>
                          <Col lg="1" md="1" sm="2" xs="2" ></Col>
                        </Row>
                      </ListGroupItemHeading>
                      {!items.length && (
                        <ListGroupItem>
                            <p className="text-center">{loading ? 'Loading...' : 'There are no items yet'}</p>
                        </ListGroupItem>
                      )}
                      {(search ? items.filter(item => {
                        const opts = { lower: true, replacement: ' ' };
                        const name = slugify(item.name, opts);
                        const city = slugify(item.city || '', opts);
                        const state = slugify(item.state || '', opts);
                        return name.includes(search) || city.includes(search) || state.includes(search);
                      }) : items).sort((a, b) => new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1).map(item => (
                        <ListGroupItem key={item.id} action onClick={() => setSelectedItem(item)}>
                          <Row>
                            <Col lg="4" md="6" sm="6" xs="10" className="text-bold text-nowrap text-truncate">
                              {item.name}
                            </Col>
                            <Col lg="3" className="d-none d-lg-block text-nowrap text-truncate">
                              {item.mobile || item.phone || ''}
                            </Col>
                            <Col lg="4" md="5" sm="4" className="d-none d-sm-block text-nowrap text-truncate">
                              {[item.city, item.state].filter(value => !!value).join(', ')}
                            </Col>
                            <Col lg="1" md="1" sm="2" xs="2" className="d-flex flex-row flex-nowrap">
                              <i className="ni ni-bold-right" />
                            </Col>
                          </Row>
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      
      <SuppliersModal 
        open={openAddModal}
        onClose={() => setOpenAddModal(false)}
        selectedItem={selectedItem}
      />
    </>
  );
}

export default SuppliersView;
