/*!

=========================================================
* Argon Dashboard PRO React - v1.2.5
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useRef } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Container,
  Row,
  Col,
  Input,
} from "reactstrap";
import { Link, NavLink } from "react-router-dom";
import { defaultRoute } from "routes";
import slugify from "slugify";

function TimelineHeader({ current, parent, actions, search }) {
  const searchTimeout = useRef();
  const renderActions = actions?.map((action) => (
    <Button key={action.label} className={action.color ? '' : "btn-neutral"} color={action.color || "default"} size="sm" onClick={action.handler}>
      {action.icon ? <i className={action.icon} /> : action.label}
    </Button>
  ));
  const onSearch = (e) => {
    clearTimeout(searchTimeout.current);
    searchTimeout.current = setTimeout(() => {
      search(slugify(e.target.value, { lower: true, replacement: ' ' }));
    }, 800);
  }
  useEffect(() => {
    return () => {
      clearTimeout(searchTimeout.current);
    }
  }, []);
  return (
    <>
      <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Breadcrumb
                    className="d-none d-md-inline-block"
                    listClassName="breadcrumb-links breadcrumb-dark"
                  >
                    <BreadcrumbItem className="text-capitalize">
                      <NavLink to={defaultRoute} tag={Link}>
                        <i className="fas fa-home" />
                      </NavLink>
                    </BreadcrumbItem>
                    {!!parent?.name && (
                    <BreadcrumbItem className="text-capitalize">
                      <NavLink to={`/admin${parent.route}`} tag={Link}>
                        {parent.name}
                      </NavLink>
                    </BreadcrumbItem>
                    )}
                    {!!current?.name && (
                      <BreadcrumbItem aria-current="page" className="active text-capitalize">
                        {current.route ? (
                          <NavLink to={`/admin${current.route}`} tag={Link}>
                            {current.name}
                          </NavLink>
                        ) : current.name}
                      </BreadcrumbItem>
                    )}
                  </Breadcrumb>
                  {search && <Input 
                    type="search" 
                    className="form-control text-white border-0 ml-2"
                    placeholder="Search"
                    style={{ 
                      borderRadius: 50,
                      background: 'rgb(87, 118, 61, 0.35)',
                    }}
                    onChange={onSearch}
                  />}
                </div>
              </Col>
              {!!actions?.length && (
                <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                  {renderActions}
                </Col>
              )}
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

TimelineHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default TimelineHeader;
