import React, { useState } from 'react';
import {
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter,
    FormGroup,
    Input,
} from "reactstrap";
import { useFirebase } from 'model/context/firebase.context';
import { useAuth } from 'model/context/auth.context'; // Import useAuth
import { COLLECTIONS } from 'model/constants';

export function ProjectCommentModal({
    internal,
    openModal,
    setModalOpen,
    setError,
}) {
    const { session } = useAuth(); // Get the current session
    const [loading, setLoading] = useState(false);
    const { db } = useFirebase();
    const [value, setValue] = useState('');

    const onUploadFile = async () => {
        try {
            setLoading(true);
            if (value?.trim() && internal.current) {
                console.log(internal);
                await db.insert(`${COLLECTIONS.projectComments}/${internal.current}`, {
                    text: value,
                    userName: session.user.displayName || session.user.email, // Use the logged-in user's name or email
                });
                setValue('');
                setModalOpen(false);
            } else {
                setError('Comment is required');
            }
        } catch (e) {
            setError(e.message || 'Cannot save the comment due to an error');
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <Modal isOpen={openModal} toggle={() => { setModalOpen(false); }}>
                <ModalHeader><h3>Add Comment</h3></ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Input
                            required
                            name="text"
                            className="form-control text-dark"
                            placeholder="Type a comment"
                            type="textarea"
                            rows={12}
                            value={value}
                            onChange={(e) => {
                                setValue(e.target.value);
                            }}
                        />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="light"
                        type="button"
                        onClick={() => {
                            setModalOpen(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="ml-4"
                        color="danger"
                        type="button"
                        onClick={onUploadFile}
                        disabled={loading}
                    >
                        Confirm
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}
