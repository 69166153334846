/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { config } from 'model/config';
import { 
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  deleteUser,
  createUserWithEmailAndPassword,
  updateProfile,
  sendPasswordResetEmail
} from 'firebase/auth';
import { useFirebase } from './firebase.context';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import { LoaderSpinner } from 'components/Loader/LoaderSpinner';
import { decodeJWT } from 'model/utils/decodeJWT';

const AuthContext = createContext();
const initialSessionState = { user: null, accessToken: null };

export const AuthProvider = ({ children }) => {
  const timeout = useRef();
  const { auth, googleAuthProvider } = useFirebase();
  const forbid = useRef(false);
  const [ready, setReady] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [session, setSession] = useState({ ...initialSessionState });
  const [error, setError] = useState('');
  const [recoveryMessage, setRecoveryMessage] = useState('');

  const validateUsedEmail = useCallback(({ idToken, user, email } = {}) => {
    let tmpEmail;
    if (!email) return false;
    if (email) tmpEmail = email;
    else if (idToken) {
      const { payload } = decodeJWT(idToken);
      tmpEmail = payload.email;
    }
    const [, domain] = tmpEmail.split('@');  
    const isValid = config.domain.trim() === domain.trim();
    if (!isValid) {
      forbid.current = true;
      if (user) deleteUser(user);
    }
    return isValid;
  }, []);

  const handleSignInError = useCallback((e) => {
    let error = e.message.replace(/Firebase:\s?/, '');
    const byPassedErrors = [
      'auth/popup-closed-by-user',
      'auth/cancelled-popup-request',
    ]
    if (
      error && byPassedErrors.some((byPassedError) => error?.includes(byPassedError))
    ) error = '';
    setError(error);
  }, []);

  const login = useCallback(async ({
    email,
    password,
    useGoogle
  } = {}) => {
    try {
      if (!useGoogle) {
        const result = await signInWithEmailAndPassword(auth, email, password);
        const { user, _tokenResponse } = result;
        const { idToken } = _tokenResponse;
        if (!validateUsedEmail({ idToken, user })) return;
      } else {
        const result = await signInWithPopup(auth, googleAuthProvider);
        const { user } = result;
        const { idToken } = GoogleAuthProvider.credentialFromResult(result);
        if (!validateUsedEmail({ idToken, user })) return;
      }
    } catch (e) {
      handleSignInError(e);
    }
  }, []);

  const logout = useCallback(async () => {
    await signOut(auth);
  }, []);

  const signUp = useCallback(async ({
    email,
    password,
    name
  } = {}) => {
    try {
      if (!validateUsedEmail({ email })) {
        throw new Error('Email account not supported');
      }
      const { user } = await createUserWithEmailAndPassword(auth, email, password);
      await updateProfile(user, {
        displayName: name
      });
    } catch (e) {
      handleSignInError(e);
    }
  }, []);

  const recoverPassword = useCallback(async (email) => {
    try {
      if (!validateUsedEmail({ email })) {
        throw new Error('Email account not supported');
      }
      await sendPasswordResetEmail(auth, email);
      setRecoveryMessage('If the email provided corresponds to a valid user account, an email with recovery steps will be sent to it.');
    } catch (e) {
      handleSignInError(e);
    }
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      let session = { ...initialSessionState };
      let authenticated = false;

      if (!user?.uid || forbid.current) logout();
      else {
        authenticated = true;
        session = {
          ...initialSessionState,
          user,
          accessToken: user.accessToken,
        }
      }
      setSession(session)
      setTimeout(() => {
        setIsAuthenticated(authenticated);
        setReady(true);
      }, 1200);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setRecoveryMessage('');
      setError('');
    }, 5000);
    return () => {
      clearTimeout(timeout.current);
    }
  }, [error, recoveryMessage]);

  if (!ready) return <LoaderSpinner />;
  return (
    <AuthContext.Provider value={{ isAuthenticated, session, login, logout, signUp, recoverPassword }}>
      <>
        {children}
        {error && (
          <div className="position-absolute fixed-top p-3 bg-danger m-2 rounded">
            <Toast>
              <ToastHeader className="text-white">
                Login Error
              </ToastHeader>
              <ToastBody className="text-white">
                {error}
              </ToastBody>
            </Toast>
          </div>
        )}
        {recoveryMessage && (
          <div className="position-absolute fixed-top p-3 bg-primary m-2 rounded">
            <Toast onClick={() => setRecoveryMessage('')}>
              <ToastHeader className="text-white">
                Recover Password
              </ToastHeader>
              <ToastBody className="text-white">
                {recoveryMessage}
              </ToastBody>
            </Toast>
          </div>
        )}
      </>
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
