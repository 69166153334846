/* eslint-disable react-hooks/exhaustive-deps */
import { LoaderSpinner } from 'components/Loader/LoaderSpinner';
import { COLLECTIONS } from 'model/constants';
import { useFirebase } from 'model/context/firebase.context';
import { nanoid } from 'nanoid';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Button, Col, FormGroup, Input, ListGroup, ListGroupItem, Modal, Row, Spinner } from 'reactstrap';
import slugify from 'slugify';

function ActivityNameInput({
    activities,
    activity,
    index,
}) {
    const [msg, setMessage] = useState();
    const [activityId, setActivityId] = useState(activity?.id || '');
    return (
        <FormGroup>
            <label className="form-control-label font-weight-light">Name</label>
            <Input
                name={`activityId-${index}`}
                className={`form-control text-dark`}
                type="text"
                readOnly
                hidden
                value={activityId}
            />
            <Input
                name={`activityName-${index}`}
                className={`form-control text-dark`}
                placeholder="Activity Name"
                type="text"
                defaultValue={activity.name}
                list="activities"
                onChange={(e) => {
                    let _msg = '';
                    const { value } = e.target;
                    if (value) {
                        const opts = { lower: true };
                        const exists = activities.find((item) => slugify(item.name || '', opts) === slugify(value || '', opts));
                        if (!exists) {
                            _msg = 'Activity not saved';
                            if (activityId) setActivityId('');
                        }
                        else setActivityId(exists.id);
                    }
                    if (_msg !== msg) setMessage(_msg);
                }}
            />
            {msg && <label className="form-control-label font-weight-light text-sm">{msg}</label>}
        </FormGroup>
    )
}
export function SubcontractorModal({
    open,
    onClose,
    activities,
    selectedItem
}) {
    const defaultActivities = () => [{ renderKey: nanoid(), id: '', name: '', date: new Date().toISOString().replace(/T.*/, ''), cost: 0 }];
    const confirmDeleteTimeout = useRef();
    const [error, setError] = useState('');
    const [errors, setErrors] = useState({});
    const [trades, setTrades] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tab, setTab] = useState(0);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [enableConfirmDelete, setEnableConfirmDelete] = useState(false);
    const [selectedActivities, setSelectedActivities] = useState(selectedItem?.activities || defaultActivities());
    const [notes, setNotes] = useState(selectedItem?.notes || []);
    const { db } = useFirebase();
    const config = {
        formId: '#new-subcontractor-form',
        collection: COLLECTIONS.subcontractors,
        typeLabel: 'Subcontractor',
    };
    const validateElement = (element) => {
        const  { required, value, type, name } = element;
        if (name === 'note' && !!value?.trim()) {
            return 'add note or delete content';
        } else if (required && !value) {
            return 'required';
        } else if (type === 'email' && !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(value)) {
            if (!value && !required) return null;
            return 'invalid email';
        }
    }
    const onValidate = () => {
        const errors = {};
        const form = document.querySelector(config.formId);
        for (let element of form.elements) {
            const  { type, name } = element;
            if (type === 'button') continue;
            const error = validateElement(element);
            if (error) errors[name] = error;
        }
        return errors;
    }
    const onSubmit = async () => {
        const errors = onValidate();
        const updatedActivities = [...selectedActivities.map(({ renderKey, ...activity}) => activity)];
        const values = {
        };

        const form = document.querySelector(config.formId);
        for (let element of form.elements) {
            const { value, checked, type, name } = element;
            if (type === 'button' || name === 'note') continue;
            if (name.match(/-\d+$/)) {
                const index = parseInt(name.match(/-(\d+)$/)[1], 10);
                const key = name.includes('activityName') ? 'name' :
                            name.includes('ActivityCost') ? 'cost' :
                            name.includes('ActivityDate') ? 'date' : 'id';
                if (key === 'cost') {
                    updatedActivities[index][key] = isNaN(parseFloat(value)) ? 0 : parseFloat(value);
                } else {
                    updatedActivities[index][key] = value.trim();
                }
                continue;
            }
            
            
            values[name] = type === 'checkbox' ? checked : (typeof value === 'string' ? value.trim() : value);
            if (type === 'number') values[name] = +values[name];
        }
        console.log('values after query selector', values);
        console.log('Activities before filtering:', updatedActivities); // Add this line to log values before filtering
        values.activities = [...updatedActivities.filter(({ renderKey, id, ...activity }) => Object.values(activity).every(value => value !== undefined && value !== null))];        console.log('Filtered Activities:', values.activities); // Add this line to log filtered activities


        if (!values.activities.length) {
            errors['Activities'] = 'at least one Activity and cost must be included';
        }
        if (Object.keys(errors).length) {
            setErrors(errors);
            setError('There are errors in the form.');
            console.log(errors);
        } else {
            setLoading(true);
            try {
                const trade = form.elements['trade']?.value;
                if (trade?.trim() && !trades.find(item => slugify(item, { lower: true }) === slugify(trade, { lower: true }))) {
                    await db.push(`${COLLECTIONS.trades}/${slugify(trade, { lower: true })}`, trade.trim());
                }
                for(let i = 0; i < values.activities.length; i++) {
                    const activity = values.activities[i];
                    if (!activity.id) {
                        const insertedKey = await db.insert(COLLECTIONS.activities, { name: activity.name });
                        values.activities[i].id = insertedKey;
                    }
                }
                if (selectedItem) {
                    await db.update(config.collection, selectedItem.id, values);
                    onClose();
                } else {
                    const insertedKey = await db.insert(config.collection, values);
                    if (insertedKey) {
                        onClose();
                    } else {
                        throw new Error('Record not generated');
                    }
                }
            } catch (e) {
                setError(`${config.typeLabel} was not saved due to an error: ${e?.messasge || e}`);
            } finally {
                setLoading(false);
            }
        }
    };
    
    const onDelete = async () => {
        setLoading(true);
        try {
            if (selectedItem) await db.delete(config.collection, selectedItem.id);
            onClose();
        } catch (e) {
            setError(`${config.typeLabel} was not deleted due to an error: ${error?.messasge}`)
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        if (!open) {
            setErrors({});
            setError('');
            setTab(0);
            setConfirmDelete(false);
            setEnableConfirmDelete(false);
            setSelectedActivities(defaultActivities());
            setNotes([]);
            clearTimeout(confirmDeleteTimeout.current);
        } else {
            setNotes(selectedItem?.notes || []);
            setSelectedActivities(selectedItem?.activities || defaultActivities());
        }
    }, [open]);

    useEffect(() => {
        setError('');
    }, [tab]);

    useEffect(() => {
        const unsubscribe = db.listen(COLLECTIONS.trades, (data) => {
          if (data) {
            setTrades(Object.values(data));
          } else {
            setTrades([]);
          }
        });
        return () => {
          unsubscribe();
        }
      }, []);

    const renderNotes = notes.sort((a, b) => new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1).map(note => (
        <ListGroupItem key={note.createdAt}>
            <div className="text-light text-xs float-right">{new Date(note.createdAt).toDateString()}</div>
            <div className="pt-4" dangerouslySetInnerHTML={{ __html: note.value.replace(/\r|\n/gi, '<br/>') }}/>
        </ListGroupItem>
    ));

    const renderActivities = selectedActivities.map((activity, index) => (
        <Fragment key={activity.renderKey || activity.id}>
            <Col md="4">
                <ActivityNameInput
                    activities={activities}
                    activity={activity}
                    index={index}
                />
            </Col>
            <Col md="4">
                <FormGroup>
                    <label className="form-control-label font-weight-light">Cost</label>
                    <Input
                        min="0"
                        name={`ActivityCost-${index}`}
                        className={`form-control text-dark`}
                        placeholder="Cost"
                        type="number"
                        defaultValue={activity.cost || ''}
                    />
                </FormGroup>
            </Col>
            <Col md="4">
                <FormGroup>
                    <label className="form-control-label font-weight-light">Cost Revision Date</label>
                    <Input
                        name={`ActivityDate-${index}`}
                        className={`form-control text-dark`}
                        placeholder="Last Cost Update Date"
                        type="date"
                        defaultValue={activity.date}
                    />
                </FormGroup>
            </Col>
            <Col md="12">
                <hr style={{ margin: '0 0 1em 0', padding: 0, }} />
            </Col>
        </Fragment>
    ));

    return (
        <Modal
            isOpen={open}
            className="modal-dialog-centered modal-white modal-lg"
        >
            <div className="p-3">
                <div className="modal-body" style={{
                    minHeight: 450,
                }}>
                    <form id={config.formId.replace('#', '')} autoComplete="off" className="h-100" onChange={(e) => {
                        const error = validateElement(e.target);
                        const name = e.target.name;
                        if (name !== 'note' || (name === 'note' && !error))
                        setErrors({ ...errors, [name]: error });
                    }}>
                        <div className="d-md-flex flex-row justify-content-between align-items-start mb-5">
                            <h3 className="text-muted font-weight-bold">
                                {tab === 0 
                                    ? 'Basic Information'
                                    : tab === 1
                                        ? 'Address'
                                        : 'Notes'
                                }
                            </h3>
                            {!!selectedItem && (
                                <div className="d-flex flex-flow justify-content-between">
                                    {confirmDelete && (
                                    <Button
                                        size="sm"
                                        color="light"
                                        type="button"
                                        onClick={() => {
                                            setConfirmDelete(false);
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    )}
                                    <Button
                                        size="sm"
                                        className="ml-4"
                                        color="danger"
                                        type="button"
                                        disabled={confirmDelete && !enableConfirmDelete}
                                        onClick={() => {
                                            if (confirmDelete) {
                                                return onDelete();
                                            }
                                            clearTimeout(confirmDeleteTimeout.current);
                                            setEnableConfirmDelete(false);
                                            confirmDeleteTimeout.current = setTimeout(() => {
                                                setEnableConfirmDelete(true);
                                            }, 2000);
                                            setConfirmDelete(!confirmDelete)
                                        }}
                                    >
                                        {confirmDelete ? 'Confirm Delete' : `Delete ${config.typeLabel}`}{confirmDelete && !enableConfirmDelete && <Spinner size="sm" className="ml-2" />}
                                    </Button>
                                </div>)}
                        </div>

                        <Row className={tab !== 0 ? "d-none" : ''}>
                            <Col md="6">
                                <FormGroup>
                                    <label className="form-control-label font-weight-light">Name<sup className={!!errors?.name ? 'text-danger' : ''}>* {errors.name}</sup></label>
                                    <Input
                                        required
                                        name="name"
                                        className={`form-control text-dark ${errors?.name ? 'is-invalid' : ''}`}
                                        placeholder="Name"
                                        type="text"
                                        defaultValue={selectedItem?.name}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label className="form-control-label font-weight-light">Email Address<sup className={!!errors?.email ? 'text-danger' : ''}>{errors.email}</sup></label>
                                    <Input
                                        name="email"
                                        className={`form-control text-dark ${errors?.email ? 'is-invalid' : ''}`}
                                        placeholder="Email Address"
                                        type="email"
                                        defaultValue={selectedItem?.email}
                                    />
                                </FormGroup>
                                <Row>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label font-weight-light">Phone (Mobile)<sup className={!!errors?.mobile ? 'text-danger' : ''}>{errors.mobile}</sup></label>
                                            <Input
                                                name="mobile"
                                                className={`form-control text-dark ${errors?.mobile ? 'is-invalid' : ''}`}
                                                placeholder="Phone (Mobile)"
                                                type="tel"
                                                defaultValue={selectedItem?.mobile}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label font-weight-light">Phone (Other)</label>
                                            <Input
                                                name="phone"
                                                className={`form-control text-dark ${errors?.phone ? 'is-invalid' : ''}`}
                                                placeholder="Phone (Other)"
                                                type="tel"
                                                defaultValue={selectedItem?.phone}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>

                            <Col md="6">
                                <FormGroup>
                                    <label className="form-control-label font-weight-light">Trade<sup className={!!errors?.trade ? 'text-danger' : ''}> {errors.trade}</sup></label>
                                    <Input
                                        name="trade"
                                        className={`form-control text-dark ${errors?.trade ? 'is-invalid' : ''}`}
                                        placeholder="trade"
                                        type="text"
                                        defaultValue={selectedItem?.trade}
                                        list="trades"
                                    />
                                    <datalist id="trades">
                                        {trades.sort().map((item) => (
                                            <option key={item} value={item} />
                                        ))}
                                    </datalist>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row className={tab !== 1 ? "d-none" : ''}>
                            <Col md="6">
                                <FormGroup>
                                    <label className="form-control-label font-weight-light">Address<sup className={!!errors?.address ? 'text-danger' : ''}>{errors.address}</sup></label>
                                    <Input
                                        name="address"
                                        className={`form-control text-dark ${errors?.address ? 'is-invalid' : ''}`}
                                        placeholder="Address"
                                        type="text"
                                        defaultValue={selectedItem?.address}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label className="form-control-label font-weight-light">Address 2</label>
                                    <Input
                                        name="address2"
                                        className={`form-control text-dark ${errors?.address2 ? 'is-invalid' : ''}`}
                                        placeholder="Address 2"
                                        type="text"
                                        defaultValue={selectedItem?.address2}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label className="form-control-label font-weight-light">City<sup className={!!errors?.city ? 'text-danger' : ''}>{errors.city}</sup></label>
                                    <Input
                                        name="city"
                                        className={`form-control text-dark ${errors?.city ? 'is-invalid' : ''}`}
                                        placeholder="City"
                                        type="text"
                                        defaultValue={selectedItem?.city}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <label className="form-control-label font-weight-light">State<sup className={!!errors?.state ? 'text-danger' : ''}>{errors.state}</sup></label>
                                    <Input
                                        name="state"
                                        className={`form-control text-dark ${errors?.state ? 'is-invalid' : ''}`}
                                        placeholder="State"
                                        type="text"
                                        defaultValue={selectedItem?.state}
                                    />
                                </FormGroup> 
                                <FormGroup>
                                    <label className="form-control-label font-weight-light">Zip / Postal Code<sup className={!!errors?.zipCode ? 'text-danger' : ''}>{errors.zipCode}</sup></label>
                                    <Input
                                        name="zipCode"
                                        className={`form-control text-dark ${errors?.zipCode ? 'is-invalid' : ''}`}
                                        placeholder="Zip / Postal Code"
                                        type="text"
                                        defaultValue={selectedItem?.zipCode}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <div className="d-flex align-items-center">
                                        <small className="form-control-label font-weight-light mr-3">
                                            Private Notes
                                        </small>
                                        <div>
                                            <label className="custom-toggle custom-toggle">
                                                <input defaultChecked={selectedItem ? !!selectedItem.privateNotes : true} type="checkbox" name="privateNotes" />
                                                <span
                                                    className="custom-toggle-slider rounded-circle"
                                                    data-label-off="No"
                                                    data-label-on="Yes"
                                                />
                                            </label>
                                        </div>
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row className={tab !== 2 ? "d-none" : ''}>
                            {renderActivities}
                            {errors?.activities && <label><sup className="text-danger">{errors.activities}</sup></label>}
                            <datalist id="activities">
                                {activities.sort().map((item) => (
                                    <option key={item.id} data-id={item.id} value={item.name} />
                                ))}
                            </datalist>

                            <Col md="12">
                                <Button
                                    size="sm" 
                                    color="link" 
                                    type="button"
                                    className="icon-sm m-auto d-flex"
                                    onClick={() => {
                                        setSelectedActivities([...selectedActivities, ...defaultActivities()])
                                    }}
                                ><i className="ni ni-fat-add" /></Button>
                            </Col>
                            
                        </Row>

                        <Row className={tab !== 3 ? "d-none h-75" : 'h-75'}>
                            <Col md="6" className="h-100">
                                <FormGroup className="h-100">
                                    <div className="d-flex flex-row justify-content-between">
                                        <label className="form-control-label font-weight-light">New Note <sup className={!!errors?.note ? 'text-danger' : ''}>{errors.note}</sup></label>
                                        <Button size="sm" color="link" type="button" onClick={() => {
                                            const form = document.querySelector(config.formId);
                                            const value = form.elements['note'].value;
                                            if (value) {
                                                form.elements['note'].value = '';
                                                setNotes([
                                                    ...notes,
                                                    {
                                                        createdAt: new Date().toISOString(),
                                                        value
                                                    }
                                                ]);
                                            }
                                        }}>Add Note</Button>
                                    </div>
                                    <Input
                                        name="note"
                                        className={`form-control text-dark h-100 ${errors?.note ? 'is-invalid' : ''}`}
                                        placeholder="Note won't be added to the list until the 'Add Note' button above is pressed."
                                        type="textarea"
                                        style={{
                                            minHeight: 270,
                                            resize: 'none'
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <ListGroup flush className="pre-scrollable d-none d-md-block">
                                    {renderNotes}
                                </ListGroup>
                                <ListGroup flush className="d-md-none d-block">
                                    {renderNotes}
                                </ListGroup>
                            </Col>
                        </Row>
                    </form>
                    {!!error && (
                        <div className="alert alert-danger" role="alert">
                            {error}
                        </div>
                    )}
                </div>
                <div className="modal-footer">
                    <Button
                        color="link"
                        type="button"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="ml-auto"
                        color="dark"
                        type="button"
                        onClick={() => setTab(tab - 1 <= 0 ? 0 : tab - 1)}
                    >
                        <i className="ni ni-bold-left" />
                    </Button>
                    {tab < 3
                    ? (     
                        <Button
                            className="ml-1"
                            color="dark"
                            type="button"
                            onClick={() => setTab(tab + 1)}
                        >
                            <i className="ni ni-bold-right" />
                        </Button>
                    )
                    : (
                        <Button
                            className="ml-1"
                            color={!!error ? 'danger' : 'info'}
                            type="button"
                            onClick={onSubmit}
                        >
                            <i className="ni ni-check-bold" />
                        </Button>
                    )}
                </div>
            </div>
            {loading && <LoaderSpinner />}
        </Modal>
    )
}