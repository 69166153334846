/* eslint-disable react-hooks/exhaustive-deps */
import { LoaderSpinner } from "components/Loader/LoaderSpinner";
import { COLLECTIONS } from "model/constants";
import { useFirebase } from "model/context/firebase.context";
import { nanoid } from "nanoid";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Modal, Row, Spinner } from "reactstrap";
import { defaultProjectTradesFormData, tabs, formValidationSchema } from "./formData";
import { getIsMac } from "utils";
import { Formik, Form } from "formik";
import ErrorDisplay from "./ErrorDisplay";
import { cloneDeep, merge } from "lodash";

// React is making a big deal about defaultProjectTradesFormData taking too long to load
const _defaultProjectTradesFormData = defaultProjectTradesFormData;
export function ProjectTradesModal({
  projectTrades,
  suppliers,
  open,
  onClose,
  selectedItem,
  onDidCreate,
  selectedTradeTask = null,
}) {
  const defaultSuppliers = () => [
    {
      renderKey: nanoid(),
      id: "",
      name: "",
      date: new Date().toISOString().replace(/T.*/, ""),
      cost: 0,
    },
  ];
  const confirmDeleteTimeout = useRef();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [enableConfirmDelete, setEnableConfirmDelete] = useState(false);
  const [projectTradesFormData, setProjectTradesFormData] = useState(_defaultProjectTradesFormData);
  const [, setSelectedSuppliers] = useState(
    selectedItem?.suppliers || defaultSuppliers()
  );
  const [tooltipText, setTooltipText] = useState("");
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);
  const [formikKey, setFormikKey] = useState(0);
  const defaultFormItems = () => {
    if(selectedTradeTask){
      const tempDefault = cloneDeep(defaultProjectTradesFormData)
      return merge(tempDefault, selectedTradeTask )
    }
    return defaultProjectTradesFormData;
  };

  useEffect(() => {
    const isMac = getIsMac();
    setTooltipText(isMac ? "Hold CMD to select multiple" : "Hold Ctrl to select multiple");

  }, []);

  useEffect(() => {
    if (open) {
      setFormikKey(prevKey => prevKey + 1);
      setHasAttemptedSubmit(false);
    }
  }, [open]);

  const { db } = useFirebase();
  const config = {
    formId: "#new-material-form",
    collection: COLLECTIONS.projectTrades,
    typeLabel: "Project Trade",
  };

  const onSubmit = async (values) => {
    console.log('on submit executing')
    setLoading(true);
    setHasAttemptedSubmit(true);
    try {
      // At this point formik and yup validated everything
      // 1. Add/Update record
      let taskId;
      if(selectedTradeTask){
        console.log('values', values)
        await db.update(config.collection, selectedTradeTask.id, values);
        taskId = selectedTradeTask.id;
      }
      else{
        taskId = await db.insert(config.collection, values);
      }
      // 2. notify parent
      if(onDidCreate) onDidCreate({ id: taskId, ...values });
      // 3. Close modal
      onClose();
    } catch (e) {
      setError(
        `${config.typeLabel} was not saved due to an error: ${e?.message || e}`
      );
    } finally {
      setLoading(false);
      // setSubmitting(false);
    }
  };

  const onDelete = async () => {
    setLoading(true);
    try {
      if (selectedItem) await db.delete(config.collection, selectedItem.id);
      onClose();
    } catch (e) {
      setError(
        `${config.typeLabel} was not deleted due to an error: ${e?.message || e}`
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!open) {
      setError("");
      setSelectedTab(0);
      setConfirmDelete(false);
      setEnableConfirmDelete(false);
      setSelectedSuppliers(defaultSuppliers());
      setProjectTradesFormData(defaultProjectTradesFormData);
      clearTimeout(confirmDeleteTimeout.current);
    } else {
      setSelectedSuppliers(selectedItem?.suppliers || defaultSuppliers());
      setProjectTradesFormData({
        ...projectTradesFormData,
        trade: selectedItem?.projectTrade || null,
      });
    }
  }, [open, selectedItem]);

  const handlePreviousTab = () => {
    if (selectedTab > 0) {
      setSelectedTab(selectedTab - 1);
    }
  };

  const handleNextTab = () => {
    if (selectedTab < tabs.length - 1) {
      setSelectedTab(selectedTab + 1);
    }
  };

  const handleSubmit = (validateForm,values) => {
    if (!hasAttemptedSubmit) {
      validateForm().then(newErrors => {
        if (Object.keys(newErrors).length > 0) {
          setHasAttemptedSubmit(true);
        } else {
          // Only submit if there are no errors
          onSubmit(values);
        }
      });
    } else {
      onSubmit(values);
    }
  };

  return (
    <Modal isOpen={open} className="modal-dialog-centered modal-white modal-xl">
      <div className="p-3">
        <div
          className="modal-body"
          style={{
            minHeight: 450,
          }}
        > 
        aaaa -> {formikKey}
          <Formik
            key={formikKey}
            initialValues={defaultFormItems()}
            validationSchema={formValidationSchema}
            onSubmit={onSubmit}
            validateOnChange={hasAttemptedSubmit}
            validateOnBlur={false}
          >
            {({ errors, isSubmitting, values, validateForm }) => (
              <Form id={config.formId.replace("#", "")} autoComplete="off" className="h-100">
                {tabs.map((Tab, index, tabs) => (<Fragment key={index}>
                  <div className="d-md-flex flex-row justify-content-between align-items-start mb-2">
                    <h3 className={`text-muted font-weight-bold ${selectedTab !== index ? `d-none` : ''}`}>
                      {Tab.title || "Details"}
                    </h3>
                    {index === 0 && !!selectedItem && (
                      <div className="d-flex flex-flow justify-content-between">
                        {confirmDelete && (
                          <Button
                            size="sm"
                            color="light"
                            type="button"
                            onClick={() => {
                              setConfirmDelete(false);
                            }}
                          >
                            Cancel
                          </Button>
                        )}
                        <Button
                          size="sm"
                          className="ml-4"
                          color="danger"
                          type="button"
                          disabled={confirmDelete && !enableConfirmDelete}
                          onClick={() => {
                            if (confirmDelete) {
                              return onDelete();
                            }
                            clearTimeout(confirmDeleteTimeout.current);
                            setEnableConfirmDelete(false);
                            confirmDeleteTimeout.current = setTimeout(() => {
                              setEnableConfirmDelete(true);
                            }, 2000);
                            setConfirmDelete(!confirmDelete);
                          }}
                        >
                          {confirmDelete
                            ? "Confirm Delete"
                            : `Delete ${config.typeLabel}`}
                          {confirmDelete && !enableConfirmDelete && (
                            <Spinner size="sm" className="ml-2" />
                          )}
                        </Button>
                      </div>
                    )}
                  </div>
                  {Tab.Component && (
                    <div
                      className={selectedTab !== index ? "d-none" : ""}
                    >
                      <Tab.Component
                        type={selectedTab !== index ? 'hidden' : ''}
                        errors={errors}
                        projectTradesFormData={projectTradesFormData}
                        projectTrades={projectTrades}
                        tooltipText={tooltipText}
                        materialProviders={suppliers.map(supplier => supplier.name)} // Pass material providers
                        values={values}
                      />
                    </div>
                  )}


                </Fragment>))}

                <Row className={selectedTab !== 1 ? "d-none" : ""}>
                  {errors?.suppliers && (
                    <label>
                      <sup className="text-danger">{errors.suppliers}</sup>
                    </label>
                  )}
                  <datalist id="suppliers">
                    {suppliers.sort().map((item) => (
                      <option key={item.id} data-id={item.id} value={item.name} />
                    ))}
                  </datalist>
                </Row>

                {!!error && (
                  <div className="alert alert-danger" role="alert">
                    {error}
                  </div>
                )}

                <div className="modal-footer">
                  <Button color="link" type="button" onClick={onClose}>
                    Cancel
                  </Button>
                  {selectedTab > 0 && (
                    <Button
                      className="ml-auto"
                      color="dark"
                      type="button"
                      onClick={handlePreviousTab}
                    >
                      <i className="ni ni-bold-left" />
                    </Button>
                  )}
                  {selectedTab < tabs.length - 1 ? (
                    <Button
                      className={selectedTab === 0 ? "ml-auto" : "ml-1"}
                      color="dark"
                      type="button"
                      onClick={handleNextTab}
                    >
                      <i className="ni ni-bold-right" />
                    </Button>
                  ) : (
                    <Button
                      className="ml-1"
                      color={!!error ? "danger" : "info"}
                      type="button"
                      disabled={isSubmitting}
                      onClick={() => handleSubmit(validateForm, values)}
                    >
                      <i className="ni ni-check-bold" />
                    </Button>
                  )}
                  <ErrorDisplay errors={errors} />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {loading && <LoaderSpinner />}
    </Modal>
  );
}