/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Container,
  Row,
  Button,
  Toast,
  ToastBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useFirebase } from "model/context/firebase.context";
import { COLLECTIONS } from "model/constants";
import { useLocation, useNavigate } from "react-router-dom";
import SimpleHeader from "components/Headers/SimpleHeader";
import { ProjectTradesModal } from "components/Project/ProjectTradesModal";
import { ProjectFormCard } from "components/Project/ProjectFormCard";
import { ProjectTradesCard } from "components/Project/ProjectTradesCard";
import { LoaderSpinner } from "components/Loader/LoaderSpinner";
import { customAlphabet as nanoid } from "nanoid";
import { ProjectImagesCard } from "components/Project/ProjectImagesCard";
import { ProjectTransactionsCard } from "components/Project/ProjectTransactionsCard";
import { ProjectCommentsTimeline } from "components/Project/ProjectCommentsTimeline"; // Ensure this import
import { transformData } from "utils";

function ProjectView() {
  const errorTimeout = useRef();
  const successTimeout = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const { db, storage } = useFirebase();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [errors, setErrors] = useState({});
  const [id, setId] = useState(location.pathname.split("/").pop());
  const internal = useRef(
    nanoid("1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ")()
  );
  const [selectedItem, setSelectedItem] = useState(null);
  const [customer, setCustomer] = useState();
  const [customers, setCustomers] = useState([]);
  const [leads, setLeads] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [projectTrades, setProjectTrades] = useState([]);
  const [projectTradesTasks, setProjectTradesTasks] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [images, setImages] = useState([]);
  const [comments, setComments] = useState([]);
  // const [addSupplyModalOpen, setAddSupplyModalOpen] = useState(false);
  const [addTradeModalOpen, setAddTradeModalOpen] = useState(false);
  const [selectedTradeTask, setSelectedTradeTask] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [, setProjectId] = useState([]);
  const [transactions, setTransactions] = useState([]);

  const validateElement = (element) => {
    const { required, value, type, name } = element;
    if (name === "note" && !!value?.trim()) {
      return "add note or delete content";
    } else if (required && !value) {
      return "required";
    } else if (
      type === "email" &&
      !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(value)
    ) {
      return "invalid email";
    }
  };
  const updateCustomer = async (customer) => {
    await db.update(
      COLLECTIONS.customers,
      customer.id,
      customer
    );
    setCustomer(customer);
  };

  const onValidate = () => {
    const errors = {};
    const form = document.querySelector("#project-form"); // Note: This doesn't work on React. You have to look into the actual form data from the state
    for (let element of form.elements) {
      const { type, name } = element;
      if (type === "button") continue;
      const error = validateElement(element);
      if (error && name) errors[name] = error;
    }
    return errors;
  };

  const onSubmit = async () => {
    const errors = onValidate();
    const values = {
      internalId: selectedItem?.internalId || internal.current,
    };

    const form = document.querySelector("#project-form");

    for (let element of form.elements) {
      const { value, checked, type, name } = element;
      if (
        !name ||
        type === "button" ||
        name === "note" ||
        name.includes("supply-")
      )
        continue;
      values[name] =
        type === "checkbox"
          ? checked
          : typeof value === "string"
          ? value.trim()
          : value;
    }

    if (Object.keys(errors).length) {
      setErrors(errors);
      setError("There are errors in the form.");
    } else {
      setLoading(true);
      try {
        values.materials = selectedMaterials.map(
          ({ id, renderKey, name, quantity, price, tax, total }) => ({
            id,
            renderKey,
            name,
            quantity: quantity || 1,
            price,
            tax,
            total,
          })
        );
        const recordExists = await db.exists(COLLECTIONS.projects, selectedItem.id);
        
        if (recordExists) {
          await db.update(COLLECTIONS.projects, selectedItem.id, values);
          setSelectedItem({ ...selectedItem, ...values });
          setSuccess(true);
        } else {
          const insertedKey = await db.insert(COLLECTIONS.projects, values);
          
          if (!insertedKey) {
            console.error('insertedKey is undefined');
            throw new Error("Record not generated: insertedKey is undefined");
          }
          setSelectedItem({ id: insertedKey, ...values });
          setSuccess(true);

          // Update the URL to reflect the new project ID
          navigate(`/admin/projects/${insertedKey}`, { replace: true });
          setId(insertedKey)
        }
      } catch (e) {
        console.error("Error in onSubmit:", e);
        setError(`Project was not saved due to an error: ${e?.message || e}`);
      } finally {
        setLoading(false);
        try {
          if (!values.customerId) {
            const newCustomer = {
              name: values.customer,
              privateNotes: false,
            };
            const insertedKey = await db.insert(
              COLLECTIONS.customers,
              newCustomer
            );
            setCustomer({
              ...newCustomer,
              id: insertedKey,
            });
          }
        } catch (e) {
          setError(
            `Customer was not saved due to an error: ${e?.message || e}`
          );
        }
      }
    }
  };

  useEffect(() => {

    let unsubscribeComments;
    let unsubscribeTransactions;
    let project;
    let unsubscribeProject;
    if (id !== "new") {
      unsubscribeProject = db.listen(COLLECTIONS.projects, projects => {
        project = projects[id];
        if(project){
          setSelectedItem(project);
        }
          if (!project?.name) throw new Error();
          setSelectedItem({ id, ...project });
          if (project.internalId) internal.current = project.internalId;
          setSelectedMaterials(project.materials || []);
          storage
            .get(`${COLLECTIONS.projects}/${internal.current}`)
            .then((result) => {
              if (result.items) setImages(result.items);
            })
            .catch(console.error);
          unsubscribeTransactions = db.listen(
            `${COLLECTIONS.projects}/${id}/transactions`,
            (data) => {
              if (data) {
                setTransactions(
                  transformData(data).sort((a, b) =>
                    new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1
                  )
                );
              } else {
                setComments([]);
              }
            }
          );
          unsubscribeComments = db.listen(
            `${COLLECTIONS.projectComments}/${internal.current}`,
            (data) => {
              if (data) {
                setComments(
                  transformData(data).sort((a, b) =>
                    new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1
                  )
                );
              } else {
                setComments([]);
              }
            }
          );
          setLoading(false);
      })
    } else {
      setSelectedItem(internal.current);
      setLoading(false);
    }

    const unsubscribeCustomers = db.listen(COLLECTIONS.customers, (data) => {
      const items = transformData(data);
      setCustomers(items);
    });

    const unsubscribeLeads = db.listen(COLLECTIONS.leads, (data) => {
      const items = transformData(data);
      setLeads(items);
    });

    const unsubscribeMaterials = db.listen(COLLECTIONS.materials, (data) => {
      const items = transformData(data);
      setMaterials(items);
    });

    const unsubscribeSuppliers = db.listen(COLLECTIONS.suppliers, (data) => {
      const items = transformData(data);
      setSuppliers(items);
    });

    const unsubscribeTrades = db.listen(COLLECTIONS.trades, (data) => {
      if (data) {
        setProjectTrades(data);
      } else {
        setProjectTrades([]);
      }
    });

    return () => {
      unsubscribeCustomers();
      unsubscribeLeads();
      unsubscribeMaterials();
      unsubscribeSuppliers();
      unsubscribeTrades();
      if (unsubscribeProject) unsubscribeProject();
      if (unsubscribeComments) unsubscribeComments();
      if (unsubscribeTransactions) unsubscribeTransactions();
    };
  }, [id]);

  const onDeleteTrade = (tradeId) => {
    const project = {...selectedItem};
    const tasks = project.tasks || [];
    const filteredTasks = tasks.filter(task => task !== tradeId);
    project.tasks = filteredTasks;
    setSelectedItem(project);
    db.update(COLLECTIONS.projects, project.id, project);
  }

  useEffect(() => {
    let unsubscribeTradeTask;
    if(selectedItem && selectedItem.tasks){
      unsubscribeTradeTask = db.listen(COLLECTIONS.projectTrades, (data) => {
        if(data){
          const transformedData = transformData(data);
          const projectTasks = selectedItem ? selectedItem.tasks : [];
          const filteredTasks = transformedData.filter(task => projectTasks.some(projectTaks => projectTaks === task.id));
          setProjectTradesTasks(filteredTasks);
      } else {
        setProjectTradesTasks([]);
      }
      });
    }
    return () => {
      if(unsubscribeTradeTask)unsubscribeTradeTask();
    }
  }, [selectedItem]);

  useEffect(() => {
    clearTimeout(errorTimeout.current);
    if (error) {
      errorTimeout.current = setTimeout(() => {
        setError(null);
      }, 5000);
    }
    return () => {
      clearTimeout(errorTimeout.current);
    };
  }, [error]);

  useEffect(() => {
    clearTimeout(successTimeout.current);
    if (success) {
      successTimeout.current = setTimeout(() => {
        setSuccess(false);
      }, 5000);
    }
    return () => {
      clearTimeout(successTimeout.current);
    };
  }, [success]);

  useEffect(() => {
    if (selectedItem) {
      setProjectId(selectedItem.id || []);
    }
  }, [selectedItem]);

  return (
    <>
      <SimpleHeader
        actions={[
          ...(selectedItem?.id
            ? [
                {
                  label: "Delete Project",
                  color: "danger",
                  handler: () => {
                    setConfirmDelete(true);
                  },
                },
              ]
            : []),
          {
            label: `${!selectedItem?.id ? "Save" : "Update"} Project`,
            handler: onSubmit,
          },
          {
            icon: `fas fa-print`,
            handler: () => {
              window.print();
            },
          },
        ]}
        current={{ name: loading ? "" : selectedItem?.name || "New" }}
        parent={{ name: "Projects", route: "/projects" }}
      />
      {!loading ? (
        <form
          id="project-form"
          autoComplete="off"
          onChange={(e) => {
            const error = validateElement(e.target);
            const name = e.target.name;
            if (name !== "note" || (name === "note" && !error))
              setErrors({ ...errors, [name]: error });
          }}
        >
          <Container className="mt--6 mb-6" fluid>
            <Row>
              <Col xs="12">
                <ProjectFormCard
                  selectedItem={selectedItem}
                  errors={errors}
                  customer={customer}
                  customers={customers}
                  leads={leads}
                  setCustomer={setCustomer}
                  updateCustomer={updateCustomer}
                />
              </Col>

              {/* <Col xs="12">
              <ProjectSuppliesCard
                materials={materials}
                errors={errors}
                selectedItem={selectedItem}
                setAddSupplyModalOpen={setAddSupplyModalOpen}
                setError={setError}
                selectedMaterials={selectedMaterials}
                setSelectedMaterials={setSelectedMaterials}
              />
            </Col> */}

              <Col xs="12">
                <ProjectTradesCard
                  newTradesDisabled={id === "new"}
                  onDeleteTrade={onDeleteTrade}
                  projectTradesTasks={projectTradesTasks}
                  materials={materials}
                  errors={errors}
                  selectedItem={selectedItem}
                  setAddTradeModalOpen={setAddTradeModalOpen}
                  setError={setError}
                  selectedMaterials={selectedMaterials}
                  setSelectedMaterials={setSelectedMaterials}
                  setSelectedTradeTask={setSelectedTradeTask}
                />
              </Col>

              <Col xs="12" md="6">
                {/* {selectedItem?.id && ( */}
                  <ProjectTransactionsCard
                    projectId={selectedItem.id && selectedItem.id}
                    transactions={transactions}
                    internal={internal}
                    comments={comments}
                    setError={setError}
                  />
                {/* )} */}
              </Col>

              <Col xs="12" md="6">
                <ProjectCommentsTimeline
                  internal={internal}
                  comments={comments}
                  setError={setError}
                />
              </Col>

              <Col xs="12" md="6">
                <ProjectImagesCard
                  internal={internal}
                  images={images}
                  setImages={setImages}
                  setError={setError}
                />
              </Col>
            </Row>
          </Container>
        </form>
      ) : (
        <LoaderSpinner />
      )}
      {/* <MaterialsModal
        open={addSupplyModalOpen}
        onClose={() => setAddSupplyModalOpen(false)}
        trades={trades}
        suppliers={suppliers}
        materials={materials}
        onDidCreate={(item) => {
          const total = +item.price + +item.tax;
          setSelectedMaterials([...selectedMaterials, { ...item, total, renderKey: nanoid(8) }]);
        }}
      />
      <Modal isOpen={confirmDelete} toggle={() => { setConfirmDelete(false); }}>
        <>
        <ModalHeader><h3>Delete project</h3></ModalHeader>
        <ModalBody>
          <p>Do you really want to delete the project?</p>
          <p>This action can't be reverted.</p>
        </ModalBody>
        <ModalFooter>
        <Button
          color="light"
          type="button"
          onClick={() => {
            setConfirmDelete(false);
          }}
        >
          Cancel
        </Button>
        <Button
          className="ml-4"
          color="danger"
          type="button"
          onClick={() => {
            db.delete(COLLECTIONS.projects, selectedItem.id).then(() => {
              if (internal.current) {
                images.forEach((image) => {
                  storage.delete(`${COLLECTIONS.projects}/${internal.current}`, image.name).catch(console.error);
                });
              }
              navigate(`/admin/projects`, { replace: true });
            });
          }}
        >
          Yes, delete
        </Button>
        </ModalFooter>
        </>
      </Modal> */}

      {/* <MaterialsModal
        open={addTradeModalOpen}
        onClose={() => setAddTradeModalOpen(false)}
        trades={trades}
        suppliers={suppliers}
        materials={materials}
        onDidCreate={(item) => {
          const total = +item.price + +item.tax;
          setSelectedMaterials([...selectedMaterials, { ...item, total, renderKey: nanoid(8) }]);
        }}
      /> */}

      <ProjectTradesModal
        open={addTradeModalOpen}
        onClose={() => setAddTradeModalOpen(false)}
        projectTrades={projectTrades}
        suppliers={suppliers}
        materials={materials}
        selectedTradeTask={selectedTradeTask}
        onDidCreate={async (values) => {
            let project = selectedItem;
            const tasks = project.tasks || [];
            tasks.push(values.id);
            project.tasks = tasks;
            await db.update(COLLECTIONS.projects, project.id, project);
        }}
      />

      <Modal
        isOpen={confirmDelete}
        toggle={() => {
          setConfirmDelete(false);
        }}
      >
        <>
          <ModalHeader>
            <h3>Delete project</h3>
          </ModalHeader>
          <ModalBody>
            <p>Do you really want to delete the project?</p>
            <p>This action can't be reverted.</p>
          </ModalBody>
          <ModalFooter>
            <Button
              color="light"
              type="button"
              onClick={() => {
                setConfirmDelete(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className="ml-4"
              color="danger"
              type="button"
              onClick={() => {
                db.delete(COLLECTIONS.projects, selectedItem.id).then(() => {
                  if (internal.current) {
                    images.forEach((image) => {
                      storage
                        .delete(
                          `${COLLECTIONS.projects}/${internal.current}`,
                          image.name
                        )
                        .catch(console.error);
                    });
                  }
                  navigate(`/admin/projects`, { replace: true });
                });
              }}
            >
              Yes, delete
            </Button>
          </ModalFooter>
        </>
      </Modal>

      {error && (
        <div className="position-absolute fixed-top p-3 bg-danger m-2 rounded">
          <Toast>
            <ToastBody className="text-white">{error}</ToastBody>
          </Toast>
        </div>
      )}
      {success && (
        <div className="position-absolute fixed-top p-3 bg-success m-2 rounded">
          <Toast>
            <ToastBody className="text-white">Saved</ToastBody>
          </Toast>
        </div>
      )}
    </>
  );
}

export default ProjectView;
