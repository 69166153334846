/*!

=========================================================
* Argon Dashboard PRO React - v1.2.5
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import { Link, NavLink } from "react-router-dom";
import { useAuth } from "model/context/auth.context";

function Login() {
  const { login } = useAuth();
  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [focusedPassword, setfocusedPassword] = React.useState(false);
  return (
    <>
      <AuthHeader
        title="Welcome!"
        lead="Use these awesome forms to login or create new account in your project for free."
      />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="border-0 mb-0 bg-white">
              <CardHeader>
                <div className="text-center text-muted mb-4">
                  <small>Sign in</small>
                </div>
                <div className="btn-wrapper text-center">
                  <Button
                    className="btn-neutral btn-icon"
                    color="default"
                    onClick={() => login({ useGoogle: true })}
                  >
                    <span className="btn-inner--icon mr-1">
                      <img
                        alt="..."
                        src={
                          require("assets/img/icons/common/google.svg").default
                        }
                      />
                    </span>
                    <span className="btn-inner--text">with Google</span>
                  </Button>
                </div>
              </CardHeader>
              <CardBody className="px-lg-3 py-lg-3">
                <div className="text-center text-muted mb-4">
                  <small>or sign in with credentials</small>
                </div>
                <Form role="form" onSubmit={(e) => {
                  e.preventDefault();
                  const form = e.target;
                  const email = form.elements['email'].value;
                  const password = form.elements['password'].value;
                  login({ email, password })
                }}>
                    <FormGroup
                      className={classnames("mb-3", {
                        focused: focusedEmail,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText className="bg-lighter text-dark">
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="bg-lighter text-dark"
                          name="email"
                          placeholder="Email"
                          type="email"
                          onFocus={() => setfocusedEmail(true)}
                          onBlur={() => setfocusedEmail(true)}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup
                      className={classnames({
                        focused: focusedPassword,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText className="bg-lighter text-dark">
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="bg-lighter text-dark"
                          name="password"
                          placeholder="Password"
                          type="password"
                          onFocus={() => setfocusedPassword(true)}
                          onBlur={() => setfocusedPassword(true)}
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="text-center">
                      <Button className="my-4" color="info" type="submit">
                        Sign in
                      </Button>
                    </div>
                  </Form>
              </CardBody>
            </Card>
            <Row className="mt-3">
              <Col xs="6">
                <NavLink
                  tag={Link}
                  className="text-light"  
                  to="/auth/lock"
                >
                  <small>Forgot password?</small>
                </NavLink>
              </Col>
              <Col className="text-right" xs="6">
                <NavLink
                  tag={Link}
                  className="text-light"  
                  to="/auth/register"
                >
                  <small>Create new account</small>
                </NavLink>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Login;
