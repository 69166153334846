import React, { useState } from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Table,
  } from "reactstrap";
import { ProjectUploadImageModal } from './ProjectUploadImageModal';
import { useFirebase } from 'model/context/firebase.context';

export function ProjectImagesCard({
    internal,
    images,
    setImages,
    setError,
}) {
    const { storage } = useFirebase();
    const [openModal, setModalOpen] = useState(false);
    const onUpdate = (image) => {
        setImages([image, ...images]);
    }
    return (
        <>
            <Card className="h-100 no-print">
                <CardHeader>
                    <div className="d-flex">
                        <h3>Images</h3>
                        <Button
                            size="sm" 
                            color="link" 
                            type="button"
                            className="ml-auto text-nowrap"
                            onClick={() => { setModalOpen(true); }}
                        >
                            Upload
                        </Button>
                    </div>
                </CardHeader>
                <CardBody>
                    {images?.length ? <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">
                                    Name
                                </th>
                            </tr>
                        </thead>
                        <tbody className="list">
                            {images.map((image) => {
                                return (
                                    <tr key={image.name}>
                                        <td>
                                            <Button
                                                color="link"
                                                onClick={() => {
                                                    storage.download(image.fullPath);
                                                }}
                                            >
                                                {image.name}
                                            </Button>
                                            <Button
                                                color="link"
                                                className="icon-only"
                                                onClick={async () => {
                                                    await storage.delete(image.fullPath);
                                                    setImages(images.filter((img) => image.name !== img.name));
                                                }}
                                            >
                                                <i className="ni ni-fat-remove" />
                                            </Button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table> : <p className="text-center">There're no images.</p>}
                </CardBody>
            </Card>
            <ProjectUploadImageModal
                internal={internal}
                openModal={openModal}
                setModalOpen={setModalOpen}
                setError={setError}
                onUpdate={onUpdate}
            />
        </>
    )
}