import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Table,
    Row,
    Col,
} from "reactstrap";
// import slugify from 'slugify';
// import { nanoid } from 'nanoid';
import moment from 'moment';

export function ProjectTradesCard({
    projectTradesTasks,
    materials,
    selectedMaterials,
    // setSelectedMaterials,
    setAddTradeModalOpen,
    setSelectedTradeTask,
    // setError,
    onDeleteTrade,
    newTradesDisabled
}) {
    const [, setTotal] = useState(0);
    // const onAddMaterial = () => {
    //     const input = document.querySelector('#project-material-search-input');
    //     const value = input.value;
    //     if (!value) return;
    //     const opts = { lower: true };
    //     const item = materials.find((material) => slugify(material.name, opts) === slugify(value, opts));
    //     if (!item) {
    //         setError('Please select an existing item');
    //     }
    //     else {
    //         input.value = "";
    //         const total = +item.price + +item.tax;
    //         setSelectedMaterials([...selectedMaterials, { ...item, total, renderKey: nanoid(8) }]);
    //     }
    // }

    const onDeleteTradeTask = (id) => () => {
        const isConfirmed = window.confirm('Are you sure you want to delete this trade task?');
        if (isConfirmed) {
            onDeleteTrade(id)
        }
        // If not confirmed, do nothing
    }
    useEffect(() => {
        const total = selectedMaterials.reduce((result, material) => {
            return result + material.total;
        }, 0);
        setTotal(total);
    }, [selectedMaterials]);
    return (
        <>
            <Card>
                <CardHeader className="no-print">
                    <Row className="align-items-center">
                        <Col xs="5">
                            <div className="d-flex">
                                {/* <Input
                                    id="project-material-search-input"
                                    required
                                    className="form-control"
                                    placeholder="Search Trade / Material"
                                    style={{
                                        borderRadius: 50,
                                    }}
                                    type="search"
                                    list="materials"
                                />
                                <Button
                                    size="sm"
                                    color="link"
                                    type="button"
                                    className="text-nowrap"
                                    onClick={onAddMaterial}
                                >
                                    Add to project
                                </Button> */}
                            </div>
                            <datalist id="materials">
                                {materials.map((material) => (
                                    <option key={material.id} value={material.name}>{material.trade}</option>
                                ))}
                            </datalist>
                        </Col>
                        <Col className="text-right" xs="7">
                            <Button
                                className="btn-neutral"
                                color="default"
                                disabled={newTradesDisabled}
                                onClick={() => {
                                    setSelectedTradeTask(null)
                                    setAddTradeModalOpen(true)}}
                                size="sm"
                            >
                                New Trade
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    {projectTradesTasks.length ? <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">
                                    Trade
                                </th>
                                <th scope="col">
                                    Estimated Start Date
                                </th>
                                <th scope="col">
                                    Estimated End Date
                                </th>
                                <th scope="col">
                                    Price
                                </th>
                                <th scope="col">

                                </th>
                            </tr>
                        </thead>
                        <tbody className="list">

                            {projectTradesTasks.map((projectTradesTask, key) => {
                                return (
                                    <React.Fragment key={key}>
                                        <tr>
                                            <td>{projectTradesTask.trade}</td>
                                            <td>{moment(projectTradesTask.plannedStartDate).format('MM/DD/YYYY')}</td>
                                            <td>{moment(projectTradesTask.plannedEndDate).format('MM/DD/YYYY')}</td>
                                            <td>${Number(projectTradesTask.estimatedCost).toFixed(2)}</td>
                                            <td><Button
                                                onClick={() => {
                                                    setSelectedTradeTask(projectTradesTask);
                                                    setAddTradeModalOpen(true);
                                                }}
                                            >Edit</Button>
                                                <Button onClick={onDeleteTradeTask(projectTradesTask.id)} color='danger'>Delete</Button></td>
                                        </tr>
                                        <tr>

                                        </tr>
                                        <tr>

                                        </tr>
                                        <tr>

                                        </tr>
                                    </React.Fragment>

                                );
                            })}
                        </tbody>
                    </Table> : <p className="text-center">The project doesn't have items yet.</p>}
                </CardBody>
            </Card>
        </>
    )
}