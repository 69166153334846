/* eslint-disable react-hooks/exhaustive-deps */
import { LoaderSpinner } from 'components/Loader/LoaderSpinner';
import { COLLECTIONS } from 'model/constants';
import { useFirebase } from 'model/context/firebase.context';
import { nanoid } from 'nanoid';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Button, Col, FormGroup, Input, ListGroup, ListGroupItem, Modal, Row, Spinner } from 'reactstrap';

export function SuppliersModal({
    open,
    onClose,
    selectedItem
}) {
    const defaultContacts = () => [{ id: nanoid(), name: '', phone: '', email: ''}];
    const confirmDeleteTimeout = useRef();
    const [error, setError] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [tab, setTab] = useState(0);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [enableConfirmDelete, setEnableConfirmDelete] = useState(false);
    const [notes, setNotes] = useState(selectedItem?.notes || []);
    const [contacts, setContacts] = useState(selectedItem?.contacts || defaultContacts());
    const { db } = useFirebase();
    const config = {
        formId: '#new-supplier-form',
        collection: COLLECTIONS.suppliers,
        typeLabel: 'Supplier',
    };
    const validateElement = (element) => {
        const  { required, value, type, name } = element;
        if (name === 'note' && !!value?.trim()) {
            return 'add note or delete content';
        } else if (required && !value) {
            return 'required';
        } else if (type === 'email' && !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(value)) {
            if (!value && !required) return null;
            return 'invalid email';
        }
    }
    const onValidate = () => {
        const errors = {};
        const form = document.querySelector(config.formId);
        for (let element of form.elements) {
            const  { type, name } = element;
            if (type === 'button') continue;
            const error = validateElement(element);
            if (error) errors[name] = error;
        }
        return errors;
    }
    const onSubmit = async () => {
        const errors = onValidate();
        const updatedContacts = [...contacts];
        const values = {
            notes,
        };
        const form = document.querySelector(config.formId);
        for (let element of form.elements) {
            const  { value, checked, type, name } = element;
            if (type === 'button' || name === 'note') continue;
            if (name.match(/-\d$/) && name.includes('contact')) {
                const index = parseInt(name.match(/\d$/)[0])
                const key = name.includes('Name') ? 'name' : name.includes('Phone') ? 'phone' : 'email'
                updatedContacts[index][key] = value?.trim();
                continue;
            }
            values[name] = type === 'checkbox' ? checked : (typeof value === 'string' ? value.trim() : value);
        }
        if (Object.keys(errors).length) {
            setErrors(errors);
            setError('There are errors in the form.');
        } else {
            values.contacts = [...updatedContacts.filter(({id, ...contact}) => Object.values(contact).some(value => !!value))];
            setLoading(true);
            try {
                if (selectedItem) {
                    await db.update(config.collection, selectedItem.id, values);
                    onClose();
                } else {
                    const insertedKey = await db.insert(config.collection, values);
                    if (insertedKey) {
                        onClose();
                    } else {
                        throw new Error('Record not generated')
                    }
                }
            } catch (e) {
                setError(`${config.typeLabel} was not saved due to an error: ${e?.messasge || e}`)
            } finally {
                setLoading(false);
            }
        }
    }
    const onDelete = async () => {
        setLoading(true);
        try {
            if (selectedItem) await db.delete(config.collection, selectedItem.id);
            onClose();
        } catch (e) {
            setError(`${config.typeLabel} was not deleted due to an error: ${e?.messasge || e}`)
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        if (!open) {
            setErrors({});
            setError('');
            setTab(0);
            setConfirmDelete(false);
            setEnableConfirmDelete(false);
            setNotes([]);
            setContacts(defaultContacts());
            clearTimeout(confirmDeleteTimeout.current);
        } else {
            setNotes(selectedItem?.notes || []);
            setContacts(selectedItem?.contacts || defaultContacts());
        }
    }, [open]);

    useEffect(() => {
        setError('');
    }, [tab]);

    const renderNotes = notes.sort((a, b) => new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1).map(note => (
        <ListGroupItem key={note.createdAt}>
            <div className="text-light text-xs float-right">{new Date(note.createdAt).toDateString()}</div>
            <div className="pt-4" dangerouslySetInnerHTML={{ __html: note.value.replace(/\r|\n/gi, '<br/>') }}/>
        </ListGroupItem>
    ));

    const renderContacts = contacts.map((contact, index) => (
        <Fragment key={contact.id}>
            <Col md="4">
                <FormGroup>
                    <label className="form-control-label font-weight-light">Name</label>
                    <Input
                        name={`contactName-${index}`}
                        className={`form-control text-dark`}
                        placeholder="Contact Name"
                        type="text"
                        defaultValue={contact.name}
                    />
                </FormGroup>
            </Col>
            <Col md="4">
                <FormGroup>
                    <label className="form-control-label font-weight-light">Phone</label>
                    <Input
                        name={`contactPhone-${index}`}
                        className={`form-control text-dark`}
                        placeholder="Contact Phone Number"
                        type="text"
                        defaultValue={contact.phone}
                    />
                </FormGroup>
            </Col>
            <Col md="4">
                <FormGroup>
                    <label className="form-control-label font-weight-light">Email</label>
                    <Input
                        name={`contactEmail-${index}`}
                        className={`form-control text-dark`}
                        placeholder="Contact Email"
                        type="text"
                        defaultValue={contact.email}
                    />
                </FormGroup>
            </Col>
            <Col md="12">
                <hr style={{ margin: '0 0 1em 0', padding: 0, }} />
            </Col>
        </Fragment>
    ));

    return (
        <Modal
            isOpen={open}
            className="modal-dialog-centered modal-white modal-lg"
        >
            <div className="p-3">
                <div className="modal-body" style={{
                    minHeight: 450,
                }}>
                    <form id={config.formId.replace('#', '')} autoComplete="off" className="h-100" onChange={(e) => {
                        const error = validateElement(e.target);
                        const name = e.target.name;
                        if (name !== 'note' || (name === 'note' && !error))
                        setErrors({ ...errors, [name]: error });
                    }}>
                        <div className="d-md-flex flex-row justify-content-between align-items-start mb-5">
                            <h3 className="text-muted font-weight-bold">
                                {tab === 0 
                                    ? 'Basic Information'
                                    : tab === 1
                                        ? 'Contacts'
                                        : 'Notes'
                                }
                            </h3>
                            {!!selectedItem && (
                                <div className="d-flex flex-flow justify-content-between">
                                    {confirmDelete && (
                                    <Button
                                        size="sm"
                                        color="light"
                                        type="button"
                                        onClick={() => {
                                            setConfirmDelete(false);
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    )}
                                    <Button
                                        size="sm"
                                        className="ml-4"
                                        color="danger"
                                        type="button"
                                        disabled={confirmDelete && !enableConfirmDelete}
                                        onClick={() => {
                                            if (confirmDelete) {
                                                return onDelete();
                                            }
                                            clearTimeout(confirmDeleteTimeout.current);
                                            setEnableConfirmDelete(false);
                                            confirmDeleteTimeout.current = setTimeout(() => {
                                                setEnableConfirmDelete(true);
                                            }, 2000);
                                            setConfirmDelete(!confirmDelete)
                                        }}
                                    >
                                        {confirmDelete ? 'Confirm Delete' : `Delete ${config.typeLabel}`}{confirmDelete && !enableConfirmDelete && <Spinner size="sm" className="ml-2" />}
                                    </Button>
                                </div>)}
                        </div>

                        <Row className={tab !== 0 ? "d-none" : ''}>
                            <Col md="6">
                                <FormGroup>
                                    <label className="form-control-label font-weight-light">Company Name<sup className={!!errors?.name ? 'text-danger' : ''}>* {errors.name}</sup></label>
                                    <Input
                                        required
                                        name="name"
                                        className={`form-control text-dark ${errors?.name ? 'is-invalid' : ''}`}
                                        placeholder="Company Name"
                                        type="text"
                                        defaultValue={selectedItem?.name}
                                    />
                                </FormGroup>
                                <Row>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label font-weight-light">Phone (Mobile)<sup className={!!errors?.mobile ? 'text-danger' : ''}>{errors.mobile}</sup></label>
                                            <Input
                                                name="mobile"
                                                className={`form-control text-dark ${errors?.mobile ? 'is-invalid' : ''}`}
                                                placeholder="Phone (Mobile)"
                                                type="tel"
                                                defaultValue={selectedItem?.mobile}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label font-weight-light">Phone (Other)</label>
                                            <Input
                                                name="phone"
                                                className={`form-control text-dark ${errors?.phone ? 'is-invalid' : ''}`}
                                                placeholder="Phone (Other)"
                                                type="tel"
                                                defaultValue={selectedItem?.phone}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <FormGroup>
                                    <label className="form-control-label font-weight-light">Address<sup className={!!errors?.address ? 'text-danger' : ''}>{errors.address}</sup></label>
                                    <Input
                                        name="address"
                                        className={`form-control text-dark ${errors?.address ? 'is-invalid' : ''}`}
                                        placeholder="Address"
                                        type="text"
                                        defaultValue={selectedItem?.address}
                                    />
                                </FormGroup>
                            </Col>

                            <Col md="6">
                                <FormGroup>
                                    <label className="form-control-label font-weight-light">Address 2</label>
                                    <Input
                                        name="address2"
                                        className={`form-control text-dark ${errors?.address2 ? 'is-invalid' : ''}`}
                                        placeholder="Address 2"
                                        type="text"
                                        defaultValue={selectedItem?.address2}
                                    />
                                </FormGroup>
                                <Row>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label font-weight-light">City<sup className={!!errors?.city ? 'text-danger' : ''}>{errors.city}</sup></label>
                                            <Input
                                                name="city"
                                                className={`form-control text-dark ${errors?.city ? 'is-invalid' : ''}`}
                                                placeholder="City"
                                                type="text"
                                                defaultValue={selectedItem?.city}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label font-weight-light">State<sup className={!!errors?.state ? 'text-danger' : ''}>{errors.state}</sup></label>
                                            <Input
                                                name="state"
                                                className={`form-control text-dark ${errors?.state ? 'is-invalid' : ''}`}
                                                placeholder="State"
                                                type="text"
                                                defaultValue={selectedItem?.state}
                                            />
                                        </FormGroup> 
                                    </Col>
                                </Row>
                                <FormGroup>
                                    <label className="form-control-label font-weight-light">Zip / Postal Code<sup className={!!errors?.zipCode ? 'text-danger' : ''}>{errors.zipCode}</sup></label>
                                    <Input
                                        name="zipCode"
                                        className={`form-control text-dark ${errors?.zipCode ? 'is-invalid' : ''}`}
                                        placeholder="Zip / Postal Code"
                                        type="text"
                                        defaultValue={selectedItem?.zipCode}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row className={tab !== 1 ? "d-none" : ''}>
                            {renderContacts}

                            <Col md="12">
                                <Button
                                    size="sm" 
                                    color="link" 
                                    type="button"
                                    className="icon-sm m-auto d-flex"
                                    onClick={() => {
                                        setContacts([...contacts, ...defaultContacts()])
                                    }}
                                ><i className="ni ni-fat-add" /></Button>
                            </Col>
                        </Row>

                        <Row className={tab !== 2 ? "d-none h-75" : 'h-75'}>
                            <Col md="6" className="h-100">
                                <FormGroup className="h-100">
                                    <div className="d-flex flex-row justify-content-between">
                                        <label className="form-control-label font-weight-light">New Note <sup className={!!errors?.note ? 'text-danger' : ''}>{errors.note}</sup></label>
                                        <Button size="sm" color="link" type="button" onClick={() => {
                                            const form = document.querySelector(config.formId);
                                            const value = form.elements['note'].value;
                                            if (value) {
                                                form.elements['note'].value = '';
                                                setNotes([
                                                    ...notes,
                                                    {
                                                        createdAt: new Date().toISOString(),
                                                        value
                                                    }
                                                ]);
                                            }
                                        }}>Add Note</Button>
                                    </div>
                                    <Input
                                        name="note"
                                        className={`form-control text-dark h-100 ${errors?.note ? 'is-invalid' : ''}`}
                                        placeholder="Note won't be added to the list until the 'Add Note' button above is pressed."
                                        type="textarea"
                                        style={{
                                            minHeight: 270,
                                            resize: 'none'
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <ListGroup flush className="pre-scrollable d-none d-md-block">
                                    {renderNotes}
                                </ListGroup>
                                <ListGroup flush className="d-md-none d-block">
                                    {renderNotes}
                                </ListGroup>
                            </Col>
                        </Row>
                    </form>
                    {!!error && (
                        <div className="alert alert-danger" role="alert">
                            {error}
                        </div>
                    )}
                </div>
                <div className="modal-footer">
                    <Button
                        color="link"
                        type="button"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="ml-auto"
                        color="dark"
                        type="button"
                        onClick={() => setTab(tab - 1 <= 0 ? 0 : tab - 1)}
                    >
                        <i className="ni ni-bold-left" />
                    </Button>
                    {tab < 2
                    ? (     
                        <Button
                            className="ml-1"
                            color="dark"
                            type="button"
                            onClick={() => setTab(tab + 1)}
                        >
                            <i className="ni ni-bold-right" />
                        </Button>
                    )
                    : (
                        <Button
                            className="ml-1"
                            color={!!error ? 'danger' : 'info'}
                            type="button"
                            onClick={onSubmit}
                        >
                            <i className="ni ni-check-bold" />
                        </Button>
                    )}
                </div>
            </div>
            {loading && <LoaderSpinner />}
        </Modal>
    )
}